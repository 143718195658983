import { Originator } from '@shared/plugin/types/originator';

export type OriginatorOption = {
  id: string;
  label: string;
  details: string;
};

export const getInitialOriginatorOption = (originator?: PartialOriginator) => {
  if (!originator?.id) return undefined;

  const transmitIds = originator?.transmitIds ?? [];
  const externalIdMappingOptions = (originator?.externalIdMappings ?? []).map(
    (mapping) => mapping.externalId
  );
  const details = [...transmitIds, ...externalIdMappingOptions].join(', ');

  return {
    id: originator.id,
    label: originator.name ?? '',
    details,
  };
};

type PartialOriginator = Pick<
  Originator,
  'transmitIds' | 'externalIdMappings'
> & { id?: string; name?: string };

export const getOriginatorOptions = (originators: PartialOriginator[]) =>
  originators
    .map(getInitialOriginatorOption)
    .filter(Boolean) as OriginatorOption[];
