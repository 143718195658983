import { useHover } from '@react-aria/interactions';
import { Button, MoreMenu, Separator, Text } from '@shared/components';
import { OptionProps } from '@shared/components/more-menu/MoreMenu';
import { useToast } from '@shared/components/toast';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import {
  useGetConversationExternalMessagesQuery,
  useTakeConversationSnapshotMutation,
} from '@shared/generated/graphql';
import { toConversationEvents } from '@shared/graphql/fromFragments/externalMessage';
import {
  ConversationContributionRole,
  ConversationEvent,
  ConversationEventType,
  EmailContribution,
  SystemMessageEvent,
  UnknownEvent,
  VoiceContribution,
} from '@shared/types/conversation';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import {
  formatAbsoluteTime,
  formatElapsedTime,
} from 'clerk_common/stringification/times';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { LuMoreVertical } from 'react-icons/lu';
import { VoiceContributionTranscript } from './VoiceContributionTranscript';

const ROOT = makeRootClassName('ConversationTranscript');
const el = makeElementClassNameFactory(ROOT);

type ConversationMetadata = {
  id: string;
  createdAt: Date;
};

export const ConversationTranscript = ({
  conversation,
  startDate,
  endDate,
  scrollToBottomFn,
}: {
  conversation: ConversationMetadata;
  startDate?: Date;
  endDate?: Date | null;
  scrollToBottomFn?: () => void;
}) => {
  const { defaultOrgId, isVoomaAdmin } = useMeContext();
  const { data } = useGetConversationExternalMessagesQuery({
    variables: {
      input: {
        conversationId: conversation.id,
        ...(defaultOrgId && { organizationIds: [defaultOrgId] }),
      },
    },
    // NOTE(parlato): We should definitely not be polling the external
    // messages table every second
    pollInterval: 1 * 1000,
  });
  const msgs = toConversationEvents(data);
  const containerRef = useRef<HTMLDivElement>(null);

  const { sendToast } = useToast();
  const [takeConversationSnapshot] = useTakeConversationSnapshotMutation();

  const toastFailure = () => {
    sendToast('Failed to take conversation snapshot', {
      variant: 'error',
    });
  };

  const takeSnapshotAtTime = async (time: Date) => {
    try {
      const res = await takeConversationSnapshot({
        variables: {
          input: {
            id: conversation.id,
            timestamp: time.toISOString(),
          },
        },
      });

      if (res.data?.takeConversationSnapshot.success) {
        sendToast('Conversation snapshot taken!', {
          variant: 'success',
        });
      } else {
        toastFailure();
      }
    } catch {
      toastFailure();
    }
  };

  useEffect(() => {
    if (scrollToBottomFn) {
      scrollToBottomFn();
      return;
    }
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [msgs.length]);

  const makeOptions = (e: ConversationEvent) => {
    if (!isVoomaAdmin) return [];
    return [
      {
        label: 'Take snapshot',
        onPress: () => takeSnapshotAtTime(e.createdAt),
      },
    ];
  };

  return (
    <div ref={containerRef} className={el`conversation-events`}>
      {msgs
        ?.filter(messageProbablyBelongsToThisExecution(startDate, endDate))
        .map((e) => (
          <ConversationEventComponent
            key={e.id}
            event={e}
            conversationStartTime={conversation.createdAt}
            moreMenuOptions={makeOptions(e)}
          />
        ))}
      {msgs.length === 0 && (
        <Text type="body-xs" className="text-gray-500">
          Waiting for someone to say something...
        </Text>
      )}
    </div>
  );
};

type ConversationEventComponentProps = {
  event: ConversationEvent;
  conversationStartTime: Date;
  moreMenuOptions?: OptionProps[];
};
function ConversationEventComponent(p: ConversationEventComponentProps) {
  switch (p.event.type) {
    case ConversationEventType.VOICE_CONTRIBUTION:
      return (
        <VoiceContributionBubble
          contribution={p.event as VoiceContribution}
          conversationStartTime={p.conversationStartTime}
          moreMenuOptions={p.moreMenuOptions}
        />
      );
    case ConversationEventType.EMAIL_CONTRIBUTION:
      return (
        <EmailContributionBubble contribution={p.event as EmailContribution} />
      );
    case ConversationEventType.SYSTEM_MESSAGE:
      return <SystemMessageBubble message={p.event as SystemMessageEvent} />;
    case ConversationEventType.UNKNOWN:
      return <UnknownEventBubble event={p.event as UnknownEvent} />;
    default:
      return null;
  }
}

export function roleToClassName(role: ConversationContributionRole) {
  switch (role) {
    case ConversationContributionRole.USER:
      return 'USER';
    case ConversationContributionRole.ASSISTANT:
      return 'ASSISTANT';
    case ConversationContributionRole.SYSTEM:
      return 'SYSTEM';
    default:
      return '';
  }
}

type VoiceContributionBubbleProps = {
  contribution: VoiceContribution;
  conversationStartTime: Date;
  moreMenuOptions?: OptionProps[];
};
const VoiceContributionBubble = ({
  contribution,
  conversationStartTime,
  moreMenuOptions,
}: VoiceContributionBubbleProps) => {
  const roleClassName = roleToClassName(contribution.role);
  const className = clsx(el`voice-contribution`, {
    [roleClassName]: true,
  });
  const wrapperClassName = clsx(el`voice-contribution-wrapper`, {
    [roleClassName]: true,
  });

  const { hoverProps, isHovered } = useHover({});
  const showMoreMenu = isHovered && (moreMenuOptions?.length || 0) > 0;

  const showOnLeft = contribution.role !== ConversationContributionRole.USER;

  return (
    <div className={wrapperClassName} {...hoverProps}>
      {showMoreMenu && showOnLeft && (
        <MoreMenu
          moreIcon={<LuMoreVertical size={12} />}
          options={moreMenuOptions}
        />
      )}
      <div className={className}>
        <VoiceContributionText contribution={contribution} />
        <Text type="body-xs" className="text-end">
          {formatElapsedTime(conversationStartTime, contribution.createdAt)}
        </Text>
      </div>
      {showMoreMenu && !showOnLeft && (
        <MoreMenu
          moreIcon={<LuMoreVertical size={12} />}
          options={moreMenuOptions}
        />
      )}
    </div>
  );
};

type VoiceContributionTextProps = {
  contribution: VoiceContribution;
};
const VoiceContributionText = ({
  contribution,
}: VoiceContributionTextProps) => {
  switch (contribution.role) {
    case ConversationContributionRole.SYSTEM:
      return <SystemMessageTranscript message={contribution.transcript} />;
    case ConversationContributionRole.ASSISTANT:
      return <Text type="body-xs">{contribution.transcript}</Text>;
    case ConversationContributionRole.USER:
      return <VoiceContributionTranscript contribution={contribution} />;
    default:
      throw new Error(`Unknown contribution role: ${contribution.role}`);
  }
};

type SystemMessageTranscriptProps = {
  message: string;
};
const SystemMessageTranscript = ({ message }: SystemMessageTranscriptProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const textClassName = clsx('flex-1 w-full', {
    'line-clamp-1': !isExpanded,
  });
  return (
    // TODO(mike): This is a very quick pass at cleaning up system messages.
    // Leaves a lot to be desired (clearer statement of tool name, etc).
    <div className="relative">
      <span className="flex max-w-full flex-row break-words">
        <Text type="body-xs" className={textClassName}>
          {message}
        </Text>
      </span>
      <div className="absolute right-0 top-0">
        <Button
          size="xs"
          isGhost
          onPress={toggleExpanded}
          icon={
            isExpanded ? (
              <ChevronUp className="text-indigo-400" />
            ) : (
              <ChevronDown className="text-indigo-400" />
            )
          }
        />
      </div>
    </div>
  );
};

type EmailContributionBubbleProps = {
  contribution: EmailContribution;
};
const EmailContributionBubble = ({
  contribution,
}: EmailContributionBubbleProps) => {
  return (
    <div className={el`email-contribution`}>
      <div className={el`email-header`}>
        <div className={el`email-header-row`}>
          <Text type="body-sm" isHeavy>
            {contribution.subject ?? '<no subject>'}
          </Text>
          <Text type="body-sm" className="text-end">
            {formatAbsoluteTime(contribution.createdAt)}
          </Text>
        </div>
        <div className={el`email-header-row`}>
          <Text type="body-sm">From: {contribution.sender}</Text>
        </div>
        <div className={el`email-header-row`}>
          <Text type="body-sm">To: {contribution.toRecipients.join(', ')}</Text>
        </div>
      </div>
      <Separator />
      <iframe
        className={el`email-iframe`}
        srcDoc={contribution.sanitizedHtmlBody}
      />
    </div>
  );
};

type UnknownEventBubbleProps = {
  event: UnknownEvent;
};
const UnknownEventBubble = ({ event }: UnknownEventBubbleProps) => {
  return (
    <div className={el`unknown-event`}>
      <Text key={event.id} type="body-xs">
        Unknown or unsupported event
      </Text>
      <Text type="body-xs" className="text-end">
        {formatAbsoluteTime(event.createdAt)}
      </Text>
    </div>
  );
};

type SystemMessageBubbleProps = {
  message: SystemMessageEvent;
};
const SystemMessageBubble = ({ message }: SystemMessageBubbleProps) => {
  const strippedMessage = message.message.replace(/<SYSTEM MESSAGE>/g, '');
  return (
    <div className={el`system-message`}>
      <Text key={message.id} type="body-xs">
        {strippedMessage}
      </Text>
      <Text type="body-xs" className="text-end">
        {formatAbsoluteTime(message.createdAt)}
      </Text>
    </div>
  );
};

function messageProbablyBelongsToThisExecution(
  startDate?: Date,
  endDate?: Date | null
): (cEvent: ConversationEvent) => boolean {
  return function (cEvent: ConversationEvent) {
    if (!startDate || !endDate) {
      return true;
    }
    const messageReceivedTime = cEvent.createdAt;

    if (!messageReceivedTime) {
      return false;
    }

    if (!endDate) {
      return messageReceivedTime.getTime() > startDate.getTime();
    }
    return (
      messageReceivedTime.getTime() < endDate.getTime() &&
      messageReceivedTime.getTime() > startDate.getTime()
    );
  };
}
