import {
  ConversationMetadata,
  ConversationStatus,
  ConversationSummary,
  ConversationType,
  PhoneConversationMetadata,
} from '@shared/generated/graphql';
import { isNil } from 'lodash';

export type Conversation = {
  id: string;
  createdAt: Date;
  type: ConversationType;
  status: ConversationStatus;
  user?: {
    id: string;
    email?: string;
    firstName?: string;
    lastName?: string;
  };
  summary?: ConversationSummary;
  metadata?: ConversationMetadata;
  mediaUrl?: string;
};

export enum ConversationContributionRole {
  USER = 'user',
  ASSISTANT = 'assistant',
  SYSTEM = 'system',
}

export enum ConversationEventType {
  VOICE_CONTRIBUTION = 'VOICE_CONTRIBUTION',
  EMAIL_CONTRIBUTION = 'EMAIL_CONTRIBUTION',
  SYSTEM_MESSAGE = 'SYSTEM_MESSAGE',
  CHAT_MESSAGE = 'CHAT_MESSAGE',
  UNKNOWN = 'UNKNOWN',
}

export type VoiceContribution = {
  id: string;
  type: ConversationEventType.VOICE_CONTRIBUTION;
  createdAt: Date;
  role: ConversationContributionRole;
  transcript: string;
};

export type EmailContribution = {
  id: string;
  type: ConversationEventType.EMAIL_CONTRIBUTION;
  createdAt: Date;
  sanitizedHtmlBody: string;
  subject: string;
  sender: string;
  toRecipients: string[];
  emailReceivedAt?: Date;
};

export type SystemMessageEvent = {
  id: string;
  type: ConversationEventType.SYSTEM_MESSAGE;
  createdAt: Date;
  message: string;
};

export type ChatMessageEvent = {
  id: string;
  type: ConversationEventType.CHAT_MESSAGE;
  createdAt: Date;
  role: ConversationContributionRole;
  message: string;
};

export type UnknownEvent = {
  id: string;
  type: ConversationEventType.UNKNOWN;
  createdAt: Date;
};

export type ConversationEvent =
  | VoiceContribution
  | EmailContribution
  | SystemMessageEvent
  | UnknownEvent
  | ChatMessageEvent;

export function isPhoneConversationMetadata(
  metadata?: ConversationMetadata
): metadata is PhoneConversationMetadata {
  return (
    !isNil(metadata) &&
    'fromPhoneNumber' in metadata &&
    'toPhoneNumber' in metadata
  );
}
