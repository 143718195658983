import { Button, Text, Tooltip } from '@shared/components';
import {
  SubscriptionType,
  UserEmailSubscription,
  useUserEmailSubscriptionsQuery,
} from '@shared/generated/graphql';
import { subscriptionFromFragment } from '@shared/graphql/fromFragments/subscription';
import { User } from '@shared/types/user';
import { StyleProps } from '@shared/utils';
import { ReactElement, useState } from 'react';
import { ConfigureEmailSubscriptionsModal } from '../configure-email-subscription';

export type EmailSubscriptionsProps = StyleProps & {
  el: (strings: TemplateStringsArray) => string;
  user: User;
};

function EmailSubscriptions({
  el,
  user,
}: EmailSubscriptionsProps): ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [configType, setConfigType] = useState<SubscriptionType>(
    SubscriptionType.OUTLOOK
  );
  const { data } = useUserEmailSubscriptionsQuery({
    variables: { id: user.id },
  });

  const emailSubscriptions = data?.userById?.emailSubscriptions;
  const activeEmailSubscriptions = emailSubscriptions
    ?.filter((subscription) => subscription.isActive)
    .map(subscriptionFromFragment);
  const inActiveEmailSubscriptions = emailSubscriptions
    ?.filter((subscription) => !subscription.isActive)
    .map(subscriptionFromFragment);

  const handleConfigureEmailSubscription = (type: SubscriptionType) => {
    setConfigType(type);
    switch (type) {
      case SubscriptionType.GMAIL:
        break;
      case SubscriptionType.OUTLOOK: {
        setIsModalOpen(true);
        break;
      }
      case SubscriptionType.FRONT:
        break;
      default:
        break;
    }
  };

  const renderLogo = (type: SubscriptionType) => {
    switch (type) {
      case SubscriptionType.GMAIL:
        return <img src={'gmail.png'} alt="Gmail" />;
      case SubscriptionType.OUTLOOK:
        return <img src={'outlook.png'} alt="Outlook" />;
      case SubscriptionType.FRONT:
        return <img src={'front.png'} alt="Front" />;
      default:
        return null;
    }
  };

  const renderSubscriptions = (subscriptions: UserEmailSubscription[]) => {
    return subscriptions.map((subscription, idx) => {
      return (
        <div
          key={`${subscription.id}-${idx}`}
          onClick={() => handleConfigureEmailSubscription(subscription.type)}
        >
          <Tooltip
            content={`${subscription.type}: ${subscription.description}`}
            isInstant
          >
            <div className={el`subscription-logo-container`}>
              {renderLogo(subscription.type)}
            </div>
          </Tooltip>
        </div>
      );
    });
  };

  return (
    <div className={el`email-subscriptions`}>
      <div>
        <Text isHeavy type="body-xs" className={el`subsection-header`}>
          Active Email Subscriptions
        </Text>
      </div>
      <div className={el`subscriptions-list`}>
        {activeEmailSubscriptions?.length ? (
          renderSubscriptions(activeEmailSubscriptions)
        ) : (
          <Text type="body-sm" className={el`empty-text`}>
            No active subscriptions
          </Text>
        )}
      </div>
      {activeEmailSubscriptions?.length === 0 && (
        <Button
          onPress={() =>
            handleConfigureEmailSubscription(SubscriptionType.OUTLOOK)
          }
          size="xs"
          variant="tertiary"
        >
          + Configure shared Outlook inbox
        </Button>
      )}
      <div>
        <Text isHeavy type="body-xs" className={el`subsection-header`}>
          Inactive Email Subscriptions
        </Text>
      </div>
      <div className={el`subscriptions-list`}>
        {inActiveEmailSubscriptions?.length ? (
          renderSubscriptions(inActiveEmailSubscriptions)
        ) : (
          <Text type="body-sm" className={el`empty-text`}>
            No inactive subscriptions
          </Text>
        )}
      </div>
      <ConfigureEmailSubscriptionsModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        userId={user.id}
        subscriptionType={configType}
        activeEmailSubscriptions={activeEmailSubscriptions ?? []}
      />
    </div>
  );
}

export default EmailSubscriptions;
