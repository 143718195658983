import { AutocompleteBase } from '@shared/components';
import { Order } from '@shared/types/order';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { debounce, isNil } from 'lodash';
import { useCallback, useMemo } from 'react';
import {
  getInitialOriginatorOption,
  OriginatorOption,
} from './getInitialOriginatorOption';

const ROOT = makeRootClassName('OriginatorSearch');
export const el = makeElementClassNameFactory(ROOT);

const ADD_ORIGINATOR_OPTION_ID = 'add-originator';

export type CreateOriginatorCallback = (
  name: string
) => Promise<string | undefined>;

interface OriginatorSearchProps {
  originator: Order['originator'];
  updateOriginator: (originatorId: string) => void;
  loading: boolean;
  originatorOptions: OriginatorOption[];
  handleSearch: (query?: string) => void;
  createOriginator?: CreateOriginatorCallback;
  originatorRequired?: boolean;
}

const shouldCreateNewOriginator = (
  createOriginator: OriginatorSearchProps['createOriginator'],
  option: OriginatorOption
): createOriginator is CreateOriginatorCallback => {
  if (isNil(createOriginator)) return false;
  return option.id === ADD_ORIGINATOR_OPTION_ID;
};

const extractOriginatorName = (label: string) => {
  return label.replace('+ Create "', '').replace('"', '');
};

const hasOption = (options: OriginatorOption[], inputValue: string) => {
  return options.some((o) =>
    o.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};

export const OriginatorSearch = (p: OriginatorSearchProps) => {
  const initialOption = useMemo(
    () => getInitialOriginatorOption(p.originator),
    [p.originator]
  );

  const handleChangeOriginator = async (option: OriginatorOption) => {
    if (shouldCreateNewOriginator(p.createOriginator, option)) {
      const name = extractOriginatorName(option.label);
      const newOrigId = await p.createOriginator(name);
      if (newOrigId) {
        p.updateOriginator(newOrigId);
      }
      return;
    }
    p.updateOriginator(option.id);
  };

  // TODO(parlato): We should also search for external ID mappings,
  // like we do for transmit codes
  const debouncedHandleSearch = useCallback(
    debounce((query) => p.handleSearch(query), 300),
    []
  );

  const filterOptions = (
    options: OriginatorOption[],
    inputValue: string
  ): OriginatorOption[] => {
    if (!inputValue) return options;
    if (hasOption(options, inputValue)) return options;
    const createOption = {
      id: ADD_ORIGINATOR_OPTION_ID,
      label: inputValue,
      details: '+ Click to create a new customer with this name',
    };
    return [...options, createOption];
  };

  return (
    <div className="flex flex-grow">
      <AutocompleteBase
        label="Customer"
        blurOnSelect
        placeholder="Select customer"
        size="small"
        defaultValue={initialOption}
        onInputChange={(_, query) => debouncedHandleSearch(query)}
        onChange={async (_, value) => {
          if (!value) return;
          await handleChangeOriginator(value as OriginatorOption);
        }}
        inputProps={{ required: p.originatorRequired }}
        options={p.originatorOptions}
        isOptionEqualToValue={(option, value) => {
          return option.id === value?.id;
        }}
        filterOptions={
          p.createOriginator
            ? (options, params) => filterOptions(options, params.inputValue)
            : undefined
        }
        validationState={initialOption?.id ? 'valid' : 'invalid'}
        expandable={true}
      />
    </div>
  );
};
