import { useToast } from '@shared/components/toast';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import {
  JobSource,
  JobType,
  useCreateEmptyJobMutation,
  useCreateEmptyQuoteMutation,
} from '@shared/generated/graphql';
// NOTE(parlato): This is brittle and will need to change if and when we
// update the quote template. However, investing more time in properly mutating
// a quote to get to an empty state is not worth it at this time.
import emptyQuoteJson from 'clerk_common/templates/quote/empty.json';

export const useCreateEmptyQuote = () => {
  const { me, defaultOrgId } = useMeContext();
  const [createJob, { loading }] = useCreateEmptyJobMutation();
  const { sendToast } = useToast();
  const [createQuote, { loading: createQuoteLoading }] =
    useCreateEmptyQuoteMutation();

  const createEmptyQuote = async () => {
    if (createQuoteLoading || loading) return;

    if (!defaultOrgId) throw new Error('No default org id');

    const res = await createJob({
      variables: {
        input: {
          organizationId: defaultOrgId,
          userId: me?.id,
          jobType: JobType.QUOTES,
          jobSource: JobSource.MANUAL_ENTRY,
          confidence: 1,
          emailMetadata: {},
        },
      },
    });

    const job = res.data?.createJob.job;
    if (!job) {
      sendToast('Failed to create job for quote', { variant: 'error' });
      return;
    }

    const { organization, user, id: jobId } = job;
    const quoteRes = await createQuote({
      variables: {
        input: {
          organizationId: organization.id,
          jobId,
          confidence: 1,
          extractedData: emptyQuoteJson,
          userId: user?.id,
        },
      },
    });

    const quote = quoteRes.data?.createQuote.quote;
    if (!quote) {
      sendToast('Failed to create quote', { variant: 'error' });
      return;
    }

    return { id: quote.id };
  };

  return {
    createEmptyQuote,
    loading,
  };
};
