import {
  getDestinationAddressFromQuote,
  getPickupAddressFromQuote,
} from '@shared/components/money-form/utils/getDATRateUrl';
import { PlaceSuggestionResults } from '@shared/hooks/usePlacesSuggestions';
import { Quote } from '@shared/types/quote';
import { DropdownQueryParam } from 'clerk_common/constants/urls/queryParams';
import { formatAddress } from 'clerk_common/stringification/address';
import { getEquipmentTypesFromFreightOrder } from 'clerk_common/templates/freight_order/fieldExtractors/fieldExtractors';
import { Address } from 'clerk_common/types/address';

const buildEquipmentTypeQueryParam = (quote: Quote): string => {
  const equipmentTypes = getEquipmentTypesFromFreightOrder(quote.extractedData);
  return equipmentTypes.length
    ? `&${DropdownQueryParam.EQUIPMENT_TYPE}=${equipmentTypes.join(',')}`
    : '';
};

const buildOriginatorQueryParam = (quote: Quote): string => {
  return quote.originator
    ? `&${DropdownQueryParam.ORIGINATOR_ID}=${quote.originator.id}`
    : '';
};

const getAddressPlaceId = async (
  address: string | Address | undefined,
  getSuggestions: (address: string) => Promise<PlaceSuggestionResults>
): Promise<string | undefined> => {
  if (!address) return '';

  const formatted =
    typeof address === 'string' ? address : formatAddress(address);
  const originSuggestions = await getSuggestions(formatted);
  if (originSuggestions.length) {
    return originSuggestions[0].place_id;
  }
};

const buildPickupAddressQueryParam = async (
  quote: Quote,
  getSuggestions: (address: string) => Promise<PlaceSuggestionResults>
): Promise<string> => {
  if (quote.extractedData) {
    const address = getPickupAddressFromQuote(quote);
    const placeId = await getAddressPlaceId(address, getSuggestions);

    if (placeId) {
      return `&${DropdownQueryParam.ORIGIN_PLACE_ID}=${placeId}`;
    }
  }
  return '';
};

const buildDestinationAddressQueryParam = async (
  quote: Quote,
  getSuggestions: (address: string) => Promise<PlaceSuggestionResults>
): Promise<string> => {
  if (quote.extractedData) {
    const address = getDestinationAddressFromQuote(quote);
    const placeId = await getAddressPlaceId(address, getSuggestions);

    if (placeId) {
      return `&${DropdownQueryParam.DESTINATION_PLACE_ID}=${placeId}`;
    }
  }
  return '';
};

export const buildQuotesPageUrl = async ({
  baseUrl,
  quote,
  getSuggestions,
}: {
  baseUrl: string;
  quote: Quote;
  getSuggestions: (address: string) => Promise<PlaceSuggestionResults>;
}): Promise<string> => {
  const originatorQueryParam = buildOriginatorQueryParam(quote);
  const equipmentTypeQueryParam = buildEquipmentTypeQueryParam(quote);
  const originPlaceIdQueryParam = await buildPickupAddressQueryParam(
    quote,
    getSuggestions
  );
  const destinationPlaceIdQueryParam = await buildDestinationAddressQueryParam(
    quote,
    getSuggestions
  );

  return `${baseUrl}${originatorQueryParam}${equipmentTypeQueryParam}${originPlaceIdQueryParam}${destinationPlaceIdQueryParam}`;
};
