import { Button, Text } from '@shared/components';
import { Dropdown } from '@shared/components/plugin-components';
import { abbreviateId } from '@shared/utils/stringification/id';
import { QueryParamName } from 'clerk_common/constants/urls/queryParams';
import { SHIPMENTS_ROUTE } from 'clerk_common/constants/urls/routes';
import { GoArrowLeft } from 'react-icons/go';
import { LuMoreVertical } from 'react-icons/lu';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

function ShipmentSidePanelHeader({
  webBaseUrl,
  shipmentId,
}: {
  webBaseUrl: string;
  shipmentId?: string;
}) {
  const navigate = useNavigate();

  const abbreviatedId = abbreviateId(shipmentId || '');

  return (
    <div className="mb-4 mt-2 flex justify-between">
      <div className="flex gap-1">
        <Button
          onPress={() => navigate('/')}
          variant="secondary"
          size="xs"
          icon={<GoArrowLeft size={12} />}
        >
          Back
        </Button>
        <a
          href={`${webBaseUrl}${SHIPMENTS_ROUTE}?${QueryParamName.SHIPMENT_ID}=${shipmentId}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            size="xs"
            icon={<MdOutlineOpenInNew className="text-brand-500" />}
            tooltip="Open in web app"
            variant="secondary"
            iconPosition="right"
          ></Button>
        </a>
      </div>
      <Dropdown
        icon={<LuMoreVertical size={12} />}
        items={[
          {
            node: (
              <div
                className="cursor-default py-2 text-gray-400"
                onClick={() => navigator.clipboard.writeText(shipmentId || '')}
              >
                <Text type="body-xs">Shipment {abbreviatedId}</Text>
              </div>
            ),
          },
        ]}
        position="right-0"
      />
    </div>
  );
}

export default ShipmentSidePanelHeader;
