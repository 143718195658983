import { Text, Tooltip } from '@shared/components';
import { VoiceContribution } from '@shared/types/conversation';

type VoiceContributionTranscriptProps = {
  contribution: VoiceContribution;
};
export const VoiceContributionTranscript = ({
  contribution,
}: VoiceContributionTranscriptProps) => {
  const segments = parseAsSegments(contribution.transcript);
  return (
    <p>
      <span className="whitespace-pre-wrap">
        <Text type="body-xs">
          {segments.map((segment) => (
            <SegmentComponent key={segment.fragment} segment={segment} />
          ))}
        </Text>
      </span>
    </p>
  );
};

type BaseSegment = {
  fragment: string;
};
type PSegment = BaseSegment & {
  tag: 'span';
};
type LowConfidenceSegment = BaseSegment & {
  tag: 'low-confidence';
  confidence: number;
};
type Segment = PSegment | LowConfidenceSegment;

function parseAsSegments(input: string): Segment[] {
  const regex = /<([a-zA-Z]+)[^>]*>(.*?)<\/\1>/g;
  let match;
  const result: Segment[] = [];
  let lastIndex = 0;

  while ((match = regex.exec(input)) !== null) {
    const [fullMatch, tag, content] = match;
    const beforeText = input.slice(lastIndex, match.index);
    if (beforeText) result.push({ fragment: beforeText, tag: 'span' });
    result.push(interpretSegment(tag, content, fullMatch));
    lastIndex = match.index + fullMatch.length;
  }
  const remainingText = input.slice(lastIndex);
  if (remainingText) result.push({ fragment: remainingText, tag: 'span' });
  return result;
}

function interpretSegment(
  tag: string,
  content: string,
  fullMatch: string
): Segment {
  switch (tag) {
    case 'span':
      return { tag: 'span', fragment: content };
    case 'LowConfTranscript':
      return {
        tag: 'low-confidence',
        fragment: content,
        confidence: parseFloat(fullMatch.match(/conf=(\d+\.\d+)/)?.[1] ?? '0'),
      };
    default:
      throw new Error(`Unknown tag: ${tag}`);
  }
}

function SegmentComponent({ segment }: { segment: Segment }) {
  switch (segment.tag) {
    case 'span':
      return <span>{segment.fragment}</span>;
    case 'low-confidence':
      return <LowConfidenceSegmentComponent segment={segment} />;
    default:
      throw new Error(`Unknown tag: ${segment}`);
  }
}

function LowConfidenceSegmentComponent({
  segment,
}: {
  segment: LowConfidenceSegment;
}) {
  return (
    <Tooltip
      content={`"${segment.fragment}" (${(segment.confidence * 100).toFixed(
        0
      )}%)`}
    >
      <span className="opacity-50 blur-[0.5px]">{segment.fragment}</span>
    </Tooltip>
  );
}
