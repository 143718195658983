import { Button, Text, Tooltip } from '@shared/components';
import { CircularProgress } from '@shared/components/plugin-components';
import { useToast } from '@shared/components/toast';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import {
  ContactMethod,
  useContactCarrierToCollectLoadBidMutation,
} from '@shared/generated/graphql';
import { useGetSuggestedCarriers } from '@shared/hooks/suggested-carriers/useGetSuggestedCarriers';
import { isNil } from 'lodash';
import { LuMail, LuPhoneOutgoing, LuTruck } from 'react-icons/lu';
import { MdRefresh } from 'react-icons/md';

type SuggestedCarriersProps = {
  shipmentId: string;
};
export const SuggestedCarriers = ({ shipmentId }: SuggestedCarriersProps) => {
  const { me } = useMeContext();
  const { suggestedCarriers, loading, error, refetch } =
    useGetSuggestedCarriers({
      shipmentId,
    });
  const hasPhoneNumber = !isNil(me?.profile?.phoneNumber);

  if (!hasPhoneNumber)
    return (
      <div className="flex flex-col gap-2">
        <Text type="body-sm" className="text-gray-500">
          Suggested Carriers
        </Text>
      </div>
    );

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center justify-between">
        <Text type="body-sm" className="text-gray-500">
          Suggested Carriers
        </Text>
        <Button
          variant="secondary"
          size="xs"
          onPress={refetch}
          icon={<MdRefresh />}
          iconPosition="right"
        >
          Refresh
        </Button>
      </div>
      {loading ? (
        <div className="flex items-center justify-center">
          <CircularProgress size="xs" />
        </div>
      ) : error ? (
        <div className="flex flex-col gap-2">
          <Text type="body-sm" isHeavy className="text-red-500">
            Failed to fetch suggested carriers.
          </Text>
          <Text type="body-sm" className="text-gray-500">
            {error.message}
          </Text>
        </div>
      ) : (
        <div className="flex h-[250px] flex-col gap-2 overflow-y-scroll">
          {suggestedCarriers.map((carrier) => (
            <SuggestedCarrierCard
              key={carrier.mcNumber}
              shipmentId={shipmentId}
              {...carrier}
            />
          ))}
        </div>
      )}
    </div>
  );
};

type SuggestedCarrierCardProps = {
  shipmentId: string;
  mcNumber: string;
  carrierName?: string;
  phoneNumber?: string;
  email?: string;
};
function SuggestedCarrierCard({
  shipmentId,
  mcNumber,
  carrierName,
  phoneNumber,
  email,
}: SuggestedCarrierCardProps) {
  const [contactCarrierToCollectLoadBid, { loading }] =
    useContactCarrierToCollectLoadBidMutation();
  const { sendToast } = useToast();

  // Jesse's phone number
  const TEMP_PHONE_NUMBER_OVERRIDE = '16506192221';
  // Sim org email address
  const TEMP_EMAIL_OVERRIDE = 'dispatch.foxtransport@gmail.com';

  const handleReachout = async (contactMethod: ContactMethod) => {
    await contactCarrierToCollectLoadBid({
      variables: {
        input: {
          shipmentId,
          contactMethod,
          carrierContact: {
            carrierName: carrierName ?? '',
            phoneNumber: TEMP_PHONE_NUMBER_OVERRIDE,
            email: TEMP_EMAIL_OVERRIDE,
          },
        },
      },
    })
      .then(() =>
        sendToast(`Triggered reachout to ${carrierName}`, {
          variant: 'success',
        })
      )
      .catch((error) =>
        sendToast(`Failed to trigger reachout to ${carrierName}`, {
          variant: 'error',
          description: error.message,
        })
      );
  };

  return (
    <div className="flex flex-col gap-1 rounded-md bg-gray-100 p-2">
      <div className="flex flex-row items-center justify-between gap-2">
        <div className="flex flex-row gap-2">
          <LuTruck />
          <Text
            isHeavy
            type="body-xs"
            className="line-clamp-1 overflow-hidden text-gray-800"
          >
            {carrierName}
          </Text>
        </div>
        <div className="flex flex-row gap-1">
          {email && (
            <Tooltip content={`Trigger an V-Mail to carrier (${email})`}>
              <div className="flex flex-row gap-1">
                <Button
                  variant="secondary"
                  size="xs"
                  icon={<LuMail />}
                  onPress={() => handleReachout(ContactMethod.EMAIL)}
                  isDisabled={loading}
                />
              </div>
            </Tooltip>
          )}
          {phoneNumber && (
            <Tooltip content={`Trigger a V-Call to carrier (${phoneNumber})`}>
              <div className="flex flex-row gap-1">
                <Button
                  variant="secondary"
                  size="xs"
                  icon={<LuPhoneOutgoing />}
                  onPress={() => handleReachout(ContactMethod.PHONE)}
                  isDisabled={loading}
                />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      <Text type="body-xs">MC #{mcNumber}</Text>
    </div>
  );
}
