import { Button, CircularProgress, Text } from '@shared/components';
import useDraftQuoteResponse from '@shared/components/draft-response/hooks/useDraftQuoteResponse';
import { TextArea } from '@shared/components/react-hook-form';
import { BaseUrlContext } from '@shared/contexts/BaseUrlContext';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { DrawerTabValue } from 'clerk_common/constants/urls/queryParams';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { AutoReplySettingsForm, getDefaultFormValues } from './types';

type AutoReplySettingsProps = {
  quoteIds: string[];
  onSubmit: (data: AutoReplySettingsForm) => void;
  loadingSubmit: boolean;
  autoReplySettings?: AutoReplySettingsForm | null;
  featureDescription?: string;
};

const ROOT = makeRootClassName('AutoReplySettings');
const el = makeElementClassNameFactory(ROOT);

const USER_SETTINGS_PATH = `/userSettings?tab=${DrawerTabValue.EMAIL_SIGNATURES}`;

export const AutoReplySettings = ({
  quoteIds,
  onSubmit,
  autoReplySettings,
  featureDescription,
  loadingSubmit,
}: AutoReplySettingsProps) => {
  const { control, handleSubmit, watch, getValues } =
    useForm<AutoReplySettingsForm>({
      defaultValues: getDefaultFormValues(autoReplySettings),
    });
  const { url } = useContext(BaseUrlContext);
  const [pulse, setPulse] = useState(false);
  const { track } = useAnalytics();

  const { message, isLoading, refetch } = useDraftQuoteResponse({
    quoteIds,
    originatorId: undefined,
    draftingInstructions: autoReplySettings?.draftingInstructions,
  });

  const submitHandler = async (data: AutoReplySettingsForm) => {
    await track('Saved Auto Rely Settings', { autoReplySettings: data });
    return onSubmit(data);
  };

  const handleTestInstructions = async () => {
    const testDraftingInstructions = watch('draftingInstructions');
    await track('Tested Drafting Instructions', {
      autoReplySettings: {
        draftingInstructions: testDraftingInstructions,
      },
    });
    await submitHandler(getValues());
    return refetch({
      input: {
        draftingInstructions: testDraftingInstructions,
        quoteIds,
      },
    });
  };

  return (
    <div className={ROOT}>
      <form onSubmit={handleSubmit(submitHandler)} className={el`form`}>
        {featureDescription && (
          <Text type="body-sm" className={el`description`}>
            {featureDescription}
          </Text>
        )}
        <div className="flex flex-col">
          <TextArea
            onBlur={() => setPulse(false)}
            onFocus={() => setPulse(true)}
            control={control}
            label="Drafting instructions"
            placeholder={`e.g. Round rates to the nearest $10\n Address sender by first name`}
            name={'draftingInstructions'}
          />
          <Button
            className={clsx(el`test-button`, pulse && 'animate-pulse')}
            variant="primary"
            onPress={handleTestInstructions}
            isDisabled={isLoading}
          >
            Test instructions
          </Button>
        </div>

        {isLoading ? (
          <div className={el`loading-container`}>
            <CircularProgress size="medium" />
          </div>
        ) : (
          <div className={el`message-container`}>
            {message?.split('\n').map((line, i) => (
              <Text key={i} type="body-xs">
                {line || '\u00A0'}
              </Text>
            ))}
          </div>
        )}

        <Button variant="secondary" type="submit" isDisabled={loadingSubmit}>
          Save settings
        </Button>
        <a
          href={`${url}${USER_SETTINGS_PATH}`}
          target="_blank"
          rel="noreferrer"
          className="flex justify-center"
        >
          <Button
            size="xs"
            icon={<MdOutlineOpenInNew className="text-brand-500" />}
            tooltip="Open in web app"
            variant="tertiary"
            iconPosition="right"
          >
            Configure my email signature
          </Button>
        </a>
      </form>
    </div>
  );
};
