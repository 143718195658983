import { Button, Text } from '@shared/components';
import { TextField } from '@shared/components/react-hook-form';
import { useToast } from '@shared/components/toast';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { useUpdateUserProfileMutation } from '@shared/generated/graphql';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { useForm } from 'react-hook-form';
import { FaCircleExclamation } from 'react-icons/fa6';

const ROOT = makeRootClassName('LoadboardDetails');
export const el = makeElementClassNameFactory(ROOT);

export const InformationNeeded = () => {
  return (
    <div className={el`information-needed-container`}>
      <div className={el`information-needed-header`}>
        <FaCircleExclamation className="text-red-500" size={14} />
        <Text isHeavy type="body-sm" className="text-gray-700">
          Information needed
        </Text>
      </div>
      <Text type="body-sm" className="text-gray-500">
        {`Vooma is missing some of the information needed to post to loadboards on your behalf`}
      </Text>
      <LoadPostProfileForm />
    </div>
  );
};

type LoadPostProfileFormData = {
  phoneNumber: string;
  extension?: string;
};

function LoadPostProfileForm() {
  const { me } = useMeContext();
  const { control, handleSubmit } = useForm<LoadPostProfileFormData>();
  const { sendToast } = useToast();
  const [updateUserProfile, { loading }] = useUpdateUserProfileMutation();

  const onSubmit = async (data: LoadPostProfileFormData) => {
    const phoneNumber =
      data.phoneNumber + (data.extension ? ` ext. ${data.extension}` : '');
    await updateUserProfile({
      variables: { input: { id: me?.id ?? '', phoneNumber } },
    })
      .then(() => {
        sendToast('Profile updated', { variant: 'success' });
      })
      .catch((error) => {
        sendToast(`Error updating profile: ${error}`, {
          variant: 'error',
        });
      });
  };

  if (!me) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
      <div className="flex flex-row gap-2">
        <TextField
          size="small"
          control={control}
          name="phoneNumber"
          label="Your phone number"
          type="tel"
          rules={{
            required: 'Phone number is required',
            pattern: {
              value: /^\d{10}$/,
              message: 'Phone number must be 10 digits',
            },
          }}
        />
        <TextField
          size="small"
          control={control}
          name="extension"
          label="Extension"
          type="tel"
        />
      </div>
      <Button
        type="submit"
        variant="secondary"
        size="small"
        className="w-full"
        isDisabled={loading}
      >
        Save to my profile
      </Button>
    </form>
  );
}
