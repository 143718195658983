import * as RadixAccordion from '@radix-ui/react-accordion';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import React, { useState } from 'react';
import { ChevronDown } from 'react-feather';

const ROOT = makeRootClassName('Accordion');
const el = makeElementClassNameFactory(ROOT);

type AccordionItemProps = {
  trigger: React.ReactNode;
  children: React.ReactNode;
  startOpen?: boolean;
};

type AccordionProps = {
  sections: AccordionItemProps[];
  triggerClassName?: string;
};

const getDefaultOpenSections = (sections: AccordionItemProps[]) => {
  return sections
    .map((section, idx) => (section.startOpen ? `item-${idx}` : null))
    .filter((section) => section !== null) as string[];
};

export const Accordion = (props: AccordionProps) => {
  const [value, setValue] = useState<string[]>(
    getDefaultOpenSections(props.sections)
  );

  return (
    <RadixAccordion.Root type="multiple" value={value} onValueChange={setValue}>
      {props.sections.map((section, idx) => (
        <AccordionItem key={idx} value={`item-${idx}`}>
          <AccordionTrigger
            startOpen={value.includes(`item-${idx}`)}
            className={clsx(el`trigger`, props.triggerClassName)}
          >
            {section.trigger}
          </AccordionTrigger>
          <AccordionContent>{section.children}</AccordionContent>
        </AccordionItem>
      ))}
    </RadixAccordion.Root>
  );
};

type ForwardedItemProps = {
  children: React.ReactNode;
  className?: string;
  value: string;
  props?: any;
};

type ForwardedProps = {
  children: React.ReactNode;
  className?: string;
  props?: any;
};

function AccordionItemComponent(
  { children, className, ...props }: ForwardedItemProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <RadixAccordion.Item className={className} {...props} ref={ref}>
      {children}
    </RadixAccordion.Item>
  );
}
const AccordionItem = React.forwardRef(AccordionItemComponent);

function AccordionTriggerComponent(
  {
    children,
    className,
    startOpen,
    ...props
  }: ForwardedProps & { startOpen?: boolean },
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const [isOpen, setIsOpen] = useState(startOpen);

  return (
    <RadixAccordion.Header>
      <RadixAccordion.Trigger
        onClick={() => setIsOpen(!isOpen)}
        className={className}
        {...props}
        ref={ref}
      >
        {children}
        <ChevronDown
          size={16}
          className={`transition-transform duration-100 ${
            isOpen ? 'rotate-180' : ''
          }`}
          aria-hidden
        />
      </RadixAccordion.Trigger>
    </RadixAccordion.Header>
  );
}
const AccordionTrigger = React.forwardRef(AccordionTriggerComponent);

function AccordionContentComponent(
  { children, ...props }: ForwardedProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <RadixAccordion.Content {...props} ref={ref}>
      {children}
    </RadixAccordion.Content>
  );
}
const AccordionContent = React.forwardRef(AccordionContentComponent);
