import { Text } from '@shared/components';
import { useLoadInitialValues } from '@shared/components/react-hook-form';
import {
  WizardFooter,
  WizardStepContainer,
  WizardStepProps,
} from '@shared/components/wizard';
import { Originator } from '@shared/plugin/types/originator';
import { ExternalIdMapping } from '@shared/types/external-id-mapping';
import { useForm } from 'react-hook-form';
import { el } from '../OriginatorOnboardingWizard';
import { OriginatorOnboardingType } from '../types';
import { ExternalIdMappingsFormSection } from './ExternalIdMappingsFormSection';
import { TransmitIdsFormSection } from './TransmitIdsFormSection';

interface TransmitIdsStepProps {
  onboardingType: OriginatorOnboardingType;
  originator: Originator;
  transmitIdsRequired: boolean;
}
export type TransmitIdsData = {
  transmitIds: string[];
  externalIdMappings: ExternalIdMapping[];
};
export function TransmitIdsStep(
  p: WizardStepProps<TransmitIdsData> & TransmitIdsStepProps
) {
  const { control, handleSubmit, getValues, reset, watch, setValue } =
    useForm<TransmitIdsData>({
      defaultValues: p.defaultValues ?? {
        transmitIds: [],
        externalIdMappings: [],
      },
    });
  useLoadInitialValues(reset, p.defaultValues as TransmitIdsData);

  const transmitIds = watch('transmitIds');
  const externalIdMappings = watch('externalIdMappings');

  const getHeaderText = () => {
    switch (p.onboardingType) {
      case OriginatorOnboardingType.FIRST_ORDER:
      case OriginatorOnboardingType.EXISTING_ORIGINATOR:
        return `Let's confirm the basics for ${p.originator.name}`;
      case OriginatorOnboardingType.NEW_ORIGINATOR:
        return `First, tell us a bit about this customer`;
    }
  };

  return (
    <WizardStepContainer
      handleSubmit={handleSubmit}
      {...p}
      className={el`wizard-step-container`}
    >
      <div className={el`step-content`}>
        <div className={el`header`}>
          <Text type="custom" isHeavy className={el`title`}>
            {getHeaderText()}
          </Text>
        </div>
        <TransmitIdsFormSection
          transmitIdFieldsName="transmitIds"
          transmitIds={transmitIds}
          setValue={setValue}
          control={control}
          required={p.transmitIdsRequired}
        />
        {/** NOTE(parlato): We can support this for order entry as well; limiting
         * this to the only known use case for now (quoting) to avoid confusion
         * for order entry users when onboarding new customers.
         */}
        {p.onboardingType === OriginatorOnboardingType.NEW_QUOTE_ORIGINATOR && (
          <ExternalIdMappingsFormSection
            externalIdMappingsFieldsName="externalIdMappings"
            externalIdMappings={externalIdMappings}
            control={control}
          />
        )}
      </div>
      <WizardFooter
        handleSubmit={handleSubmit}
        getValues={getValues}
        {...p}
        className={el`footer`}
      />
    </WizardStepContainer>
  );
}
