import {
  ExternalMessageFragment,
  ExternalMessageType,
  GetConversationExternalMessagesQuery,
} from '@shared/generated/graphql';
import {
  ConversationContributionRole,
  ConversationEvent,
  ConversationEventType,
} from '@shared/types/conversation';
import DOMPurify from 'dompurify';

export const conversationEventFromExternalMessageFragment = (
  fragment: ExternalMessageFragment
): ConversationEvent => {
  switch (fragment.type) {
    case ExternalMessageType.VOICE_TRANSCRIPT:
      return {
        id: fragment.id,
        type: ConversationEventType.VOICE_CONTRIBUTION,
        createdAt: new Date(fragment.createdAt),
        role: fragment.content?.role ?? ConversationContributionRole.USER,
        transcript: fragment.content?.transcript ?? '',
      };
    case ExternalMessageType.GMAIL_MESSAGE:
    case ExternalMessageType.MICROSOFT_MESSAGE:
    case ExternalMessageType.VMAIL_MESSAGE:
      return {
        id: fragment.id,
        type: ConversationEventType.EMAIL_CONTRIBUTION,
        createdAt: new Date(fragment.createdAt),
        sanitizedHtmlBody: fragment.htmlBody
          ? DOMPurify.sanitize(fragment.htmlBody)
          : '',
        subject: fragment.emailMetadata?.subject ?? '',
        sender: fragment.emailMetadata?.sender ?? '',
        toRecipients: fragment.emailMetadata?.toRecipients ?? [],
        emailReceivedAt:
          fragment.emailMetadata.receivedDateTime &&
          new Date(fragment.emailMetadata.receivedDateTime),
      };
    case ExternalMessageType.SYSTEM_MESSAGE:
      return {
        id: fragment.id,
        type: ConversationEventType.SYSTEM_MESSAGE,
        createdAt: new Date(fragment.createdAt),
        message: fragment.content ?? '',
      };
    case ExternalMessageType.CHAT_MESSAGE:
      return {
        id: fragment.id,
        type: ConversationEventType.CHAT_MESSAGE,
        createdAt: new Date(fragment.createdAt),
        role: fragment.content?.role ?? ConversationContributionRole.USER,
        message: fragment.content?.transcript ?? '',
      };
    default:
      return {
        id: fragment.id,
        type: ConversationEventType.UNKNOWN,
        createdAt: new Date(fragment.createdAt),
      };
  }
};

export const toConversationEvents = (
  query?: GetConversationExternalMessagesQuery
): ConversationEvent[] => {
  return (
    query?.conversationExternalMessages?.edges.map((e) =>
      conversationEventFromExternalMessageFragment(e.node)
    ) ?? []
  );
};
