import { Text } from '@shared/components';
import ChatInput from '@shared/components/chat-input/ChatInput';
import FeedScrollContainer from '@shared/components/feed-scroll-container/FeedScrollContainer';
import { useConciergeContext } from '@shared/plugin/contexts/ConciergeContext';
import { useFeedContext } from '@shared/plugin/contexts/FeedContext';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { reverse } from 'lodash';
import { useMemo } from 'react';
import { FeedItem } from './components/feed-item/FeedItem';
import {
  FeedFilterOption,
  FeedItem as FeedItemModel,
  FeedItemType,
} from './types';
import { itemComparator } from './utils/combineItems';
import { groupItemsByDate } from './utils/groupItemsByDate';

const ROOT = makeRootClassName('Feed');
const el = makeElementClassNameFactory(ROOT);

const FeedGroupingHeader = ({ header }: { header: string }) => {
  return (
    <div className={el`feed-grouping-header`}>
      <div className={el`feed-grouping-header-divider`} />
      <Text type="custom">{header}</Text>
      <div className={el`feed-grouping-header-divider`} />
    </div>
  );
};

const FeedGrouping = ({
  header,
  items,
}: {
  header?: string;
  items: FeedItemModel[];
}) => {
  const sortedItems = reverse(items.sort(itemComparator));
  return (
    <>
      {header && <FeedGroupingHeader header={header} />}
      {sortedItems.map((item, idx) => {
        return (
          <FeedItem
            key={`feed-item-${idx}-${item.createdAt}-${item.type}`}
            item={item}
          />
        );
      })}
    </>
  );
};

export const Feed = () => {
  const {
    feed: { items, suggestedActions },
    filter,
  } = useFeedContext();
  const { hasAccessToConcierge } = useConciergeContext();

  const filteredItems = useMemo(() => {
    if (filter.selectedOption === FeedFilterOption.QUOTES) {
      return items.filter((item) => {
        return (
          item.type === FeedItemType.JOB_CARD && item.data.job.quotes.length > 0
        );
      });
    }

    if (filter.selectedOption === FeedFilterOption.ORDERS) {
      return items.filter((item) => {
        return (
          item.type === FeedItemType.JOB_CARD && item.data.job.orders.length > 0
        );
      });
    }

    return items;
  }, [items, filter]);

  const groupedItems = groupItemsByDate(filteredItems);
  const groupedItemsCount = groupedItems.reduce((acc, { items }) => {
    return acc + items.length;
  }, 0);

  return (
    <div className={el`feed-root`}>
      <FeedScrollContainer
        className={el`feed-items`}
        length={groupedItemsCount}
      >
        {groupedItems.map(({ header, items }) => {
          return <FeedGrouping key={header} header={header} items={items} />;
        })}
      </FeedScrollContainer>
      <div className={el`suggested-actions`}>
        <div>
          {suggestedActions.map((item, idx) => {
            return (
              <FeedItem
                key={`feed-item-${idx}-${item.createdAt}-${item.type}`}
                item={item}
              />
            );
          })}
        </div>
        {hasAccessToConcierge && <ChatInput />}
      </div>
    </div>
  );
};
