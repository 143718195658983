import { Text } from '@shared/components';
import { KeyValueArray } from '@shared/components/react-hook-form';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';

import {
  getAvailableIntegrationTypes,
  integrationTypesToSelectOptions,
} from '@shared/plugin/pages/OriginatorOnboarding/utils/getAvailableIntegrationTypes';
import { ExternalIdMapping } from '@shared/types/external-id-mapping';
import { ArrayPath, Control, FieldValues } from 'react-hook-form';

type ExternalIdMappingsFormSectionProps<T extends FieldValues> = {
  externalIdMappingsFieldsName: ArrayPath<T>;
  externalIdMappings: ExternalIdMapping[];
  control: Control<T>;
};
export function ExternalIdMappingsFormSection<T extends FieldValues>(
  p: ExternalIdMappingsFormSectionProps<T>
) {
  const { integrationConfigs } = useMeContext();
  const externalIdMappingTypeOptions =
    getAvailableIntegrationTypes(integrationConfigs);
  const options = integrationTypesToSelectOptions(externalIdMappingTypeOptions);

  if (!externalIdMappingTypeOptions?.length) {
    return null;
  }

  return (
    <div className="flex w-full flex-col items-start gap-2">
      <div className="mb-2">
        <Text type="body-md">
          Are there <i>integration specific</i> codes?
        </Text>
        <Text type="body-xs" className="text-gray-500">
          Some customers may have identifiers specific to certain integrations.
        </Text>
      </div>

      <KeyValueArray
        arrayLabel="Integration Codes"
        elementLabel="External ID Mapping"
        control={p.control}
        name={p.externalIdMappingsFieldsName}
        keyName="integrationType"
        valueName="externalId"
        typeOptions={options}
        defaultType={externalIdMappingTypeOptions[0]}
      />
    </div>
  );
}
