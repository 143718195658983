import { FullStory } from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import {
  FrontProvider,
  Organization,
  useGetFeatureFlagEnabledQuery,
  UserRole,
} from '@shared/generated/graphql';
import { toName, User } from '@shared/types/user';
import { hasProducts } from '@shared/utils/permissions';
import { UserFeatures } from 'clerk_common';
import { FeatureFlagName } from 'clerk_common/types/featureFlags';
import { createContext, useEffect, useMemo } from 'react';

import { IntegrationConfigs } from '@shared/types/order';
import { Me } from './hooks/useMe';

export function getUserFromMe(me: Me): User | undefined {
  if (!me) return undefined;

  return {
    id: me.id,
    userName: me.username ?? '',
    name: toName({ firstName: me.firstName, lastName: me.lastName }),
    email: me?.email ?? '',
    roles: me.roles,
    features: me.features as UserFeatures,
  };
}

type UseMeState = {
  me: Me | null;
  loading: boolean;
  refetch: () => void;
};

export type MeContextType = {
  me: Me | null;
  defaultOrgId: string | null;
  defaultOrgName: string | null;
  loading: boolean;
  isVoomaAdmin: boolean;
  isVoomaOperations: boolean;
  hasQuotes: boolean;
  hasOrders: boolean;
  hasCalls: boolean;
  hasWorkers: boolean;
  hasEmailScanning: boolean;
  frontEmailProvider: FrontProvider | null;
  integrationConfigs: IntegrationConfigs | null;
  refetch: () => void;
};

export const MeContext = createContext<MeContextType>({
  loading: false,
  me: null,
  defaultOrgId: null,
  defaultOrgName: null,
  isVoomaAdmin: false,
  isVoomaOperations: false,
  hasQuotes: false,
  hasOrders: false,
  hasCalls: false,
  hasWorkers: false,
  hasEmailScanning: false,
  frontEmailProvider: null,
  integrationConfigs: null,
  refetch: () => null,
});

export const MeContextProvider = ({
  children,
  useMe,
  enableFullStory = true,
}: {
  children: JSX.Element;
  useMe: () => UseMeState;
  enableFullStory?: boolean;
}) => {
  const { me, loading, refetch } = useMe();

  const defaultOrgId = me?.organizationsDetails[0]?.id ?? null;
  const defaultOrgName = me?.organizationsDetails[0]?.name ?? null;
  const integrationConfigs =
    me?.organizationsDetails[0]?.integrationConfigs ?? null;
  const frontEmailProvider =
    me?.organizationsDetails[0]?.settings?.front?.emailProvider ?? null;

  const isVoomaAdmin = useMemo(() => {
    return Boolean(me?.roles.includes(UserRole.ADMIN));
  }, [me]);

  const isVoomaOperations = useMemo(() => {
    return Boolean(
      me?.roles.includes(UserRole.OPERATIONS) ||
        me?.roles.includes(UserRole.ADMIN)
    );
  }, [me]);

  const { hasQuotes, hasOrders, hasEmailScanning, hasWorkers, hasCalls } =
    useMemo(
      () => hasProducts((me?.organizationsDetails as Organization[]) ?? []),
      [me?.organizationsDetails]
    );

  const hasBuildOrderButtonFlag =
    useGetFeatureFlagEnabledQuery({
      variables: {
        input: {
          name: FeatureFlagName.BUILD_ORDER_BUTTON,
          userId: me?.id,
          organizationId: defaultOrgId,
        },
      },
      pollInterval: 30 * 1000,
    }).data?.isFeatureFlagEnabled === true;

  const hasBuildQuoteButtonFlag =
    useGetFeatureFlagEnabledQuery({
      variables: {
        input: {
          name: FeatureFlagName.BUILD_QUOTE_BUTTON,
          userId: me?.id,
          organizationId: defaultOrgId,
        },
      },
      pollInterval: 30 * 1000,
    }).data?.isFeatureFlagEnabled === true;

  const hasViewCallsButtonFlag =
    useGetFeatureFlagEnabledQuery({
      variables: {
        input: {
          name: FeatureFlagName.VIEW_CALLS_BUTTON,
          userId: me?.id,
          organizationId: defaultOrgId,
        },
      },
      pollInterval: 30 * 1000,
    }).data?.isFeatureFlagEnabled === true;

  const hasBuildOrderButton = hasOrders && hasBuildOrderButtonFlag;
  const hasBuildQuoteButton = hasQuotes && hasBuildQuoteButtonFlag;
  const hasViewCallsButton = hasCalls && hasViewCallsButtonFlag;

  useEffect(() => {
    if (me?.id && me?.email) {
      Sentry.setUser({ id: me?.id, email: me?.email });
      enableFullStory &&
        FullStory('setIdentityAsync', { uid: me.id }).catch((e) => {
          Sentry.captureException(`Issue with FullStory initialization, ${e}`);
        });
    }
  }, [me?.id]);

  return (
    <MeContext.Provider
      value={{
        isVoomaAdmin,
        isVoomaOperations,
        me,
        defaultOrgId,
        defaultOrgName,
        loading,
        hasQuotes: hasBuildQuoteButton,
        hasOrders: hasBuildOrderButton,
        hasCalls: hasViewCallsButton,
        hasWorkers,
        hasEmailScanning,
        frontEmailProvider,
        integrationConfigs,
        refetch,
      }}
    >
      {children}
    </MeContext.Provider>
  );
};
