export enum FeatureFlagName {
  BUILD_ORDER_BUTTON = 'BUILD_ORDER_BUTTON',
  BUILD_QUOTE_BUTTON = 'BUILD_QUOTE_BUTTON',
  DEPRECATE_USER_ALLOW_LISTS = 'DEPRECATE_USER_ALLOW_LISTS',
  DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD',
  EMAIL_CONVERSATIONS = 'EMAIL_CONVERSATIONS',
  LOAD_SHIPMENT_BY_ID = 'LOAD_SHIPMENT_BY_ID',
  ORDER_PRESUBMIT_VALIDATION = 'ORDER_PRESUBMIT_VALIDATION',
  PAUSE_MESSAGE_CLASSIFICATION = 'PAUSE_MESSAGE_CLASSIFICATION',
  QUOTE_UPDATE_FROM_TMS_IMPORT = 'QUOTE_UPDATE_FROM_TMS_IMPORT',
  QUOTES_CONDITIONAL_AUTO_REPLY = 'QUOTES_CONDITIONAL_AUTO_REPLY',
  SCHEDULING = 'SCHEDULING',
  TRACK_LOADS_IN_SIDEKICK = 'TRACK_LOADS_IN_SIDEKICK',
  VIEW_CALLS_BUTTON = 'VIEW_CALLS_BUTTON',
  ENFORCE_VOICE_GUARDRAILS = 'ENFORCE_VOICE_GUARDRAILS',
  CONCIERGE_CHAT = 'CONCIERGE_CHAT',
  ATTACH_EMAIL_SIGNATURES = 'ATTACH_EMAIL_SIGNATURES',
  CARRIER_OFFERS = 'CARRIER_OFFERS',
}
