import { MoreMenu, Text } from '@shared/components';
import { formatTime } from '@shared/plugin/utils/datetime';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';

const ROOT = makeRootClassName('CarrierOffers');
export const el = makeElementClassNameFactory(ROOT);

const MOCK_CARRIER_OFFERS = [
  {
    carrierName: 'Eight Star Transport',
    mcNumber: '211084',
    offer: 1225,
    createdAt: new Date(new Date().setHours(7, 34, 0, 0)),
  },
  {
    carrierName: 'Apex Trucking',
    mcNumber: '292742',
    offer: 1150,
    createdAt: new Date(new Date().setHours(7, 52, 0, 0)),
  },
];

export const CarrierOffers = () => {
  return (
    <div className="flex flex-col gap-3">
      <Text type="body-sm" className="text-gray-500">
        Carrier offers
      </Text>
      <div className="flex max-h-[250px] flex-col gap-2 overflow-y-scroll">
        {MOCK_CARRIER_OFFERS.map((offer) => (
          <CarrierOfferCard key={offer.mcNumber} {...offer} />
        ))}
      </div>
    </div>
  );
};

type CarrierOfferCardProps = {
  createdAt: Date;
  carrierName: string;
  offer: number;
};
function CarrierOfferCard({
  createdAt,
  carrierName,
  offer,
}: CarrierOfferCardProps) {
  const options = [
    {
      label: 'Contact carrier to book',
      onClick: () => {},
    },
    {
      label: 'View negotiation',
      onClick: () => {},
    },
    {
      label: 'View carrier details',
      onClick: () => {},
    },
  ];

  return (
    <div className="flex flex-col gap-1 rounded-md bg-gray-100 p-2">
      <div className="flex flex-row items-center justify-between gap-2">
        <div className="flex flex-row gap-1">
          <Text type="body-xs" isHeavy>
            ${offer}
          </Text>
          <Text
            type="body-xs"
            className="line-clamp-1 overflow-hidden text-gray-800"
          >
            - {carrierName}
          </Text>
        </div>
        <div className="flex flex-row items-center gap-2">
          <Text type="body-xs" className="text-gray-500">
            {formatTime(createdAt)}
          </Text>
          <MoreMenu options={options} />
        </div>
      </div>
    </div>
  );
}
