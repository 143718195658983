import { AutocompleteBase, Button, Modal, Text } from '@shared/components';
import { useToast } from '@shared/components/toast';
import {
  AuthTokenType,
  SubscriptionType,
  UserQuery,
  useUpdateUserFeaturesMutation,
  useUserQuery,
  useUserSharedInboxesQuery,
} from '@shared/generated/graphql';
import { EmailSubscription } from '@shared/graphql/fromFragments/subscription';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { enumToString } from 'clerk_common/enums';
import { UserFeature } from 'clerk_common/types';
import { useState } from 'react';
import { InboxOption, SelectedSharedInbox } from '../user-features-form/types';

const ROOT = makeRootClassName('ConfigureEmailSubscriptionsModal');
const el = makeElementClassNameFactory(ROOT);

const getSharedInboxId = (userQuery?: UserQuery): string | undefined => {
  const features = userQuery?.userById.features?.features ?? [];

  const emailScanningFeature = features.find(
    (feature: UserFeature) => feature.name === 'EmailScanning'
  );

  return emailScanningFeature?.config?.outlookSharedInboxId;
};

type ConfigureEmailSubscriptionsModalProps = {
  open: boolean;
  setOpen: (val: boolean) => void;
  userId: string;
  subscriptionType: SubscriptionType;
  activeEmailSubscriptions: EmailSubscription[];
};
export const ConfigureEmailSubscriptionsModal = ({
  open,
  setOpen,
  userId,
  subscriptionType,
  activeEmailSubscriptions,
}: ConfigureEmailSubscriptionsModalProps) => {
  const [updateUserFeatures] = useUpdateUserFeaturesMutation();
  const { data } = useUserQuery({
    variables: {
      id: userId,
    },
  });
  const { sendToast } = useToast();
  const [query, setQuery] = useState('');
  const [sharedInboxOption, setSharedInboxOption] = useState<
    SelectedSharedInbox | undefined
  >(undefined);
  const sharedInboxId = getSharedInboxId(data);
  const hasSharedInboxConfigured = Boolean(sharedInboxId);
  const activeSubscriptions = activeEmailSubscriptions
    .filter((s) => s.type === subscriptionType)
    .map((s) => s.description)
    .join(', ');

  const { data: sharedInboxesData } = useUserSharedInboxesQuery({
    variables: {
      id: userId,
      input: {
        authTokenType: AuthTokenType.MICROSOFT_GRAPH_API,
        query,
      },
    },
  });

  const handleSuccess = (message: string) => {
    sendToast(message, { variant: 'success' });
  };

  const handleUpdateUserSharedInbox = async () => {
    if (!sharedInboxOption) return;

    await updateUserFeatures({
      refetchQueries: ['User', 'UserEmailSubscriptions'],
      variables: {
        input: {
          id: userId,
          features: {
            features: [
              {
                enabled: true,
                name: 'EmailScanning',
                config: {
                  outlookSharedInboxId: sharedInboxOption.id,
                },
              },
            ],
          },
        },
      },
    });

    setSharedInboxOption(undefined);

    handleSuccess('Shared inbox scanning enabled');
  };

  const handleClearSharedInbox = async () => {
    await updateUserFeatures({
      refetchQueries: ['User'],
      variables: {
        input: {
          id: userId,
          features: {
            features: [
              {
                enabled: true,
                name: 'EmailScanning',
                config: {
                  outlookSharedInboxId: null,
                },
              },
            ],
          },
        },
      },
    });

    handleSuccess('Shared inbox scanning disabled');
  };

  const handleSelect = (id: string) => {
    setSharedInboxOption(
      sharedInboxesData?.userById?.sharedInboxes?.find(
        (inbox) => inbox.id === id
      )
    );
  };

  const options = (sharedInboxesData?.userById?.sharedInboxes?.map((inbox) => ({
    id: inbox.id,
    label: `${inbox.displayName} (${inbox.name})`,
  })) ?? []) as InboxOption[];

  return (
    <Modal.Root open={open} onOpenChange={setOpen}>
      <Modal.Content size="responsive-takeover" className={ROOT}>
        <Modal.Header
          className={el`header`}
          title={`Configure ${enumToString(subscriptionType)} Subscription`}
          hasCloseButton
          onClose={() => setOpen(false)}
        />
        <div className={el`content`}>
          <Text type="body-xs" className={el`active-subscriptions`}>
            Active {enumToString(subscriptionType)} subscriptions:{' '}
            {activeSubscriptions}
          </Text>
          <div className={el`autocomplete`}>
            <AutocompleteBase
              label="Search for shared inbox"
              blurOnSelect
              placeholder="shared@company.com e.g."
              size="small"
              onInputChange={(_, query) => setQuery(query)}
              onChange={async (_, value) => {
                if (!value) {
                  setSharedInboxOption(undefined);
                  return;
                }

                handleSelect((value as InboxOption).id);
              }}
              options={options}
            />
          </div>
        </div>
        <Modal.Footer className={el`footer`}>
          <Button
            isDisabled={!hasSharedInboxConfigured}
            variant="danger"
            onPress={handleClearSharedInbox}
            size="medium"
            className={el`action-button`}
          >
            Disable Shared Inbox
          </Button>
          <Button
            onPress={handleUpdateUserSharedInbox}
            variant="primary"
            isDisabled={!sharedInboxOption?.id}
            size="medium"
            className={el`action-button`}
          >
            {`Enable ${sharedInboxOption?.displayName ?? ''}`}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
