import { Listbox, Transition } from '@headlessui/react';
import { useFocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { createCheck, createChevronDown } from '@shared/assets/icons';
import { useOptionalRef } from '@shared/hooks';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@shared/utils';
import clsx from 'clsx';
import {
  Children,
  ComponentType,
  ForwardedRef,
  forwardRef,
  Fragment,
  ReactElement,
  ReactNode,
} from 'react';
import { Icon } from '..';

/**
 * Extract HeadlessUI props from Listbox
 * @see https://github.com/tailwindlabs/headlessui/issues/1394#issuecomment-1126686516
 */
type ExtractProps<T> = T extends ComponentType<infer P> ? P : T;

type ListboxProps = ExtractProps<typeof Listbox>;

export type OptionProps<T> = {
  /**
   * The value of the option.
   * Used by the select parent's renderValue to render the option
   * when selected and used in the state of the select.
   */
  value: T;

  /**
   * The content of the option.
   */
  children: ReactNode;
  /** Whether the option is disabled. */
  isDisabled?: boolean;
};

export type SelectProps<T> = StyleProps &
  Pick<ListboxProps, 'name'> & {
    /** Whether to show required styles */
    isRequired?: boolean;

    /**
     * Whether the input should be non-interactive and
     * show disabled styles.
     * @default false */
    isDisabled?: boolean;

    /**
     * The size of the input. Use custom to override through css.
     * @default 'medium'
     */
    size?: 'medium' | 'small' | 'custom';

    /**
     * The option elements to populate the select menu with.
     */
    children: ReactElement<OptionProps<T>>[];

    /**
     * Handler to call when an option is selected.
     */
    onChange?: (value: T) => void;

    /** Handler to call when the input is blurred */
    onBlur?: () => void;

    /**
     * The input value. Providing null will select no
     * options. If the value is an object, it must have reference
     * equality with the option's value to be selected. If the value
     * is not an object, the string representation must match with the
     * string representation of the option to be selected.
     */
    value?: T;

    /**
     * Handler to customize how the input value is rendered. Useful
     * for complex option types.
     */
    renderValue?: (value: T) => ReactNode;

    /** Message to put in the input when no option is selected @default 'Select...'*/
    placeholder?: string;

    /** Adds a styled label to the select input */
    label?: string | ReactNode;

    /**
     * Whether to show valid or invalid styles
     */
    validationState?: 'valid' | 'invalid';

    inputClassName?: string;

    /**
     * Additional children to be included in the input wrapper.
     * Useful for customization (searchfield, etc.)
     */
    wrapperChildren?: ReactElement | ReactElement[];

    wrapperChildrenPlacement?: 'inside' | 'outside';
  };

const ROOT = makeRootClassName('Select');
const el = makeElementClassNameFactory(ROOT);

const DROPDOWN_ICON = createChevronDown;
const SELECTED_ICON = createCheck;

const DEFAULT_PROPS = {
  placeholder: 'Select...',
  size: 'medium',
  isDisabled: false,
  isRequired: false,
} as const;

export function Option<T>(props: OptionProps<T>): ReactElement {
  return <>{props.children}</>;
}

export function makePlaceholder(placeholder: string): ReactNode {
  return <span className={el`placeholder`}>{placeholder}</span>;
}

export function getValueToDisplay<T>(
  value: T | undefined,
  placeholder: string,
  renderValue?: (value: T) => ReactNode
): ReactNode {
  const placeholderNode = makePlaceholder(placeholder);
  if (!value) return placeholderNode;
  const valueRenderer = renderValue ?? ((v) => v);
  return valueRenderer(value) || placeholderNode;
}

function SelectComponent<T>(
  props: SelectProps<T>,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const domRef = useOptionalRef(ref);

  // @TODO create optional uncontrolled state

  const { hoverProps, isHovered } = useHover({ isDisabled: p.isDisabled });
  const { focusProps, isFocusVisible, isFocused } = useFocusRing();
  const behaviorProps = mergeProps(hoverProps, focusProps);

  return (
    <Listbox
      name={p.name}
      as="div"
      value={p.value}
      onBlur={p.onBlur}
      disabled={p.isDisabled}
      className={clsx(
        `${ROOT} size-${p.size}`,
        {
          'is-required': p.isRequired,
          'is-disabled': p.isDisabled,
          'is-valid': p.validationState === 'valid',
          'is-invalid': p.validationState === 'invalid',
          'is-hovered': isHovered,
          'is-focus-visible': isFocusVisible,
          'is-focused': isFocused,
        },
        p.className
      )}
    >
      {() => (
        <>
          {p.label && (
            <label className={el`label`}>
              {p.isRequired && <div className={el`label-required-indicator`} />}
              <span className={el`label-span`}>{p.label}</span>
            </label>
          )}
          <div className={el`wrapper-and-children`}>
            <Listbox.Button
              {...behaviorProps}
              tabIndex={0}
              ref={domRef}
              as="div"
              className={clsx(el`input-wrapper`, p.inputClassName)}
            >
              <div className={el`text`}>
                {getValueToDisplay(p.value, p.placeholder, p.renderValue)}
              </div>
              <Icon content={DROPDOWN_ICON} className={el`dropdown-icon`} />
              {p.wrapperChildrenPlacement !== 'outside' &&
                p.wrapperChildren &&
                p.wrapperChildren}
            </Listbox.Button>
            {p.wrapperChildrenPlacement === 'outside' &&
              p.wrapperChildren &&
              p.wrapperChildren}
            <Transition
              as={Fragment}
              enter="transition duration-100 ease-in"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition duration-75 ease-in"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className={clsx(el`menu`)}>
                {Children.map(p.children, ({ props }, idx) => (
                  <Listbox.Option
                    key={idx}
                    value={props.value}
                    disabled={props.isDisabled}
                    as={'div'}
                  >
                    {({ active, selected }) => (
                      <li
                        className={clsx(el`option`, {
                          'is-selected': selected,
                          'is-active': active,
                          'is-disabled': props.isDisabled,
                        })}
                        onClick={() => {
                          if (props.value !== undefined) {
                            p.onChange?.(props.value);
                          }
                        }}
                      >
                        {props.children}
                        {selected && (
                          <Icon
                            content={SELECTED_ICON}
                            className={el`selected-icon`}
                          />
                        )}
                      </li>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export const Select = forwardRef(SelectComponent);

//@TODO switch to radix rather than headlessui it's when ready
export default Select;
