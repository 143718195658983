import * as ToggleGroup from '@radix-ui/react-toggle-group';

import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import { ReactElement } from 'react';

const ROOT = makeRootClassName('Toggle');
const el = makeElementClassNameFactory(ROOT);

export type ToggleOption = {
  label: string;
  value: string;
};

export type ToggleProps = {
  children: ToggleOption[];
  defaultValue?: string;
  value?: string;
  onValueChange?: (value: string) => void;
  variant?: 'default';
  isDisabled?: boolean;
};

const DEFAULT_PROPS = {
  variant: 'default',
} as const;

function Toggle(props: ToggleProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <ToggleGroup.Root
      className={ROOT}
      type="single"
      defaultValue="bar"
      value={p.value}
      onValueChange={p.onValueChange}
      disabled={p.isDisabled}
    >
      {p.children.map((child, index) => {
        return (
          <ToggleGroup.Item
            className={clsx(el`toggle-group-item`, `variant-${p.variant}`, {
              'is-selected': child.value === p.value,
            })}
            key={index}
            value={child.value}
          >
            {child.label}
          </ToggleGroup.Item>
        );
      })}
    </ToggleGroup.Root>
  );
}

export default Toggle;
