import { CircularProgress } from '@shared/components';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { useGetFeatureFlagEnabledQuery } from '@shared/generated/graphql';
import { useChatWithConcierge } from '@shared/hooks/concierge/useChatWithConcierge';
import { FeatureFlagName } from 'clerk_common/types';
import { createContext, useContext } from 'react';

type ConciergeContextType = {
  conciergeConversationId: string;
  sendMessageToConcierge: (message: string) => Promise<void>;
  loading: boolean;
  hasAccessToConcierge: boolean;
  organizationId: string;
};

export const ConciergeContext = createContext<ConciergeContextType>({
  conciergeConversationId: '',
  sendMessageToConcierge: () => Promise.resolve(),
  loading: false,
  hasAccessToConcierge: false,
  organizationId: '',
});

export const ConciergeContextProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { defaultOrgId, me } = useMeContext();
  if (!defaultOrgId || !me) {
    return children;
  }
  return (
    <ConciergeContextProviderHOC organizationId={defaultOrgId} userId={me.id}>
      {children}
    </ConciergeContextProviderHOC>
  );
};

type ConciergeContextProviderHOCProps = {
  organizationId: string;
  userId: string;
  children: JSX.Element;
};

const ConciergeContextProviderHOC = ({
  organizationId,
  userId,
  children,
}: ConciergeContextProviderHOCProps) => {
  const ff = useGetFeatureFlagEnabledQuery({
    variables: {
      input: {
        name: FeatureFlagName.CONCIERGE_CHAT,
        userId,
        organizationId,
      },
    },
  });
  const hasAccessToConcierge = ff.data?.isFeatureFlagEnabled ?? false;

  const { conciergeConversationId, sendMessageToConcierge, isAddingMessage } =
    useChatWithConcierge({ organizationId });

  if (!conciergeConversationId) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <ConciergeContext.Provider
      value={{
        hasAccessToConcierge,
        conciergeConversationId,
        sendMessageToConcierge,
        loading: isAddingMessage,
        organizationId,
      }}
    >
      {children}
    </ConciergeContext.Provider>
  );
};

export function useConciergeContext() {
  const context = useContext(ConciergeContext);
  if (!context) {
    throw new Error(
      'useConciergeContext must be used within a ConciergeContext.Provider'
    );
  }
  return context;
}
