import { Text } from '@shared/components';
import { ActionTimelineEvent } from '@shared/components/events-timeline/components/action-timeline-event';
import { useToast } from '@shared/components/toast';
import {
  TriggerPortalAppointmentSchedulingMutation,
  useTriggerPortalAppointmentSchedulingMutation as useSchedulePortal,
} from '@shared/generated/graphql';
import { useGetAvailabilityCheck } from '@shared/hooks/useSchedulePortalAvailability';
import { AvailabilityInputFields } from 'clerk_common/types';
import { useState } from 'react';
import { Portal } from '../Portal';
import { ScheduleButton, StopProps } from './Stop';

export function PortalStop({ stop, index, shipmentId }: StopProps) {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [refetchLoading, setRefetchLoading] = useState<boolean>(false);
  const [inputFields, setInputFields] = useState<AvailabilityInputFields>();

  const result = useGetAvailabilityCheck({ shipmentId, index });
  const fields = result.check?.fields;
  const { loading: availCheckLoading, refetch } = result;

  const options = { onCompleted: onPortalCompleted };
  const [triggerPortal, ctx] = useSchedulePortal(options);
  const { loading: scheduleLoading } = ctx;

  const { sendToast } = useToast();

  async function schedulePortalStop() {
    const stopsIndexToSchedule = index;
    const dt = selectedDate;
    const input = { shipmentId, stopsIndexToSchedule, dt, fields: inputFields };
    await triggerPortal({ variables: { input } });
  }

  function onPortalCompleted(data: TriggerPortalAppointmentSchedulingMutation) {
    const { success, error } = data.triggerPortalAppointmentScheduling;
    if (!success && error) {
      sendToast(error);
    }
    if (success) {
      sendToast('Appointment scheduled.');
    }
  }

  async function handleCustomFieldsChange(
    inputFields: AvailabilityInputFields
  ) {
    const input = { shipmentId, stopsIndex: index, fields: inputFields };
    setRefetchLoading(true);
    await refetch({ input });
    setRefetchLoading(false);
  }

  const portalLoading = availCheckLoading || refetchLoading;

  return (
    <ActionTimelineEvent type={stop.stopType}>
      <div className="mb-4 ml-2 mt-1">
        <Text isHeavy type="body-xs">
          {stop.locationLabel}
        </Text>
        <Text type="body-xs">{stop.address.addressOne}</Text>
        <div className="my-3 rounded-md border bg-gray-50 p-2">
          {!portalLoading && (
            <Portal
              type={stop.placeSchedulePortalType!}
              times={result.check?.times ?? []}
              timeZone={stop.placeTimeZone}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              info={result.check?.info}
              fields={fields ?? undefined}
              handleFieldsChange={handleCustomFieldsChange}
              setInputFields={setInputFields}
            />
          )}
          {portalLoading && <p>Loading...</p>}
        </div>
        <div>
          <ScheduleButton
            onPress={schedulePortalStop}
            disabled={scheduleLoading}
          />
        </div>
      </div>
    </ActionTimelineEvent>
  );
}
