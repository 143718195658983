import { ApolloQueryResult } from '@apollo/client';
import { Text } from '@shared/components';
import { GetShipmentDetailsQuery } from '@shared/generated/graphql';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { ScheduleStop } from 'clerk_common/types';
import { MdRoute } from 'react-icons/md';
import { VoomaCover } from './VoomaCover';
import { VoomaSchedule } from './VoomaSchedule/VoomaSchedule';
import { VoomaTrack } from './VoomaTrack';

const ROOT = makeRootClassName('ShipmentCard');
const el = makeElementClassNameFactory(ROOT);

type ShipmentCardProps = {
  referenceNumber: string;
  shipmentId: string;
  stops: ScheduleStop[];
  trackingInfo: {
    driverName?: string;
    driverPhone?: string;
  };
  tmsId?: string;
  refetchShipment: () => Promise<ApolloQueryResult<GetShipmentDetailsQuery>>;
};
export function ShipmentCard({
  referenceNumber,
  shipmentId,
  stops,
  tmsId,
  trackingInfo,
  refetchShipment,
}: ShipmentCardProps) {
  return (
    <div className={el`shipment-card`}>
      <ShipmentCardHeader referenceNumber={referenceNumber} tmsId={tmsId} />
      <VoomaSchedule
        shipmentId={shipmentId}
        stops={stops}
        refetchShipment={refetchShipment}
      />
      <VoomaCover shipmentId={shipmentId} />
      <VoomaTrack shipmentId={shipmentId} trackingInfo={trackingInfo} />
    </div>
  );
}

type ShipmentCardHeaderProps = {
  referenceNumber: string;
  tmsId?: string;
};

function ShipmentCardHeader({
  referenceNumber,
  tmsId,
}: ShipmentCardHeaderProps) {
  return (
    <div className={el`shipment-header-refnum`}>
      <ShipmentCardHeaderIcon />
      <div className="flex flex-1 flex-col items-start">
        <Text
          type="body-sm"
          isHeavy
          className="ml-2 line-clamp-1 text-gray-700"
        >
          {tmsId || referenceNumber}
        </Text>
      </div>
    </div>
  );
}

function ShipmentCardHeaderIcon() {
  return (
    <div className="flex items-center justify-center rounded-md bg-gray-100 p-1 text-gray-600">
      <MdRoute size={14} />
    </div>
  );
}
