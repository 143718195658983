import { useNavigate } from 'react-router-dom';
import { OriginatorOnboardingType } from '../components/OriginatorOnboardingWizard/types';
import { getQuotingOnboardingRoute } from '../OriginatorOnboarding';

export const useInitiateQuoteOriginatorOnboarding = ({
  quoteId,
}: {
  quoteId: string;
}) => {
  const navigate = useNavigate();

  const initiateOriginatorOnboarding = async (originatorId: string) => {
    navigate(
      getQuotingOnboardingRoute(
        OriginatorOnboardingType.NEW_QUOTE_ORIGINATOR,
        quoteId,
        originatorId
      )
    );
    return originatorId;
  };

  return {
    initiateOriginatorOnboarding,
  };
};
