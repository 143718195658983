import {
  Accordion,
  Button,
  Separator,
  Text,
  Tooltip,
} from '@shared/components';
import { useToast } from '@shared/components/toast';
import { useTriggerTrackingProcedureMutation } from '@shared/generated/graphql';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { useOrganizationFeatureFlag } from '@shared/hooks/useOrganizationFeatureFlag';
import { FeatureFlagName } from 'clerk_common/types';
import { LuPhoneOutgoing } from 'react-icons/lu';
import { TbSteeringWheel } from 'react-icons/tb';

type VoomaTrackProps = {
  shipmentId: string;
  trackingInfo: {
    driverName?: string;
    driverPhone?: string;
  };
};
export function VoomaTrack({ shipmentId, trackingInfo }: VoomaTrackProps) {
  const mayTrackLoad = useOrganizationFeatureFlag({
    featureFlagName: FeatureFlagName.TRACK_LOADS_IN_SIDEKICK,
  });

  if (!mayTrackLoad) return null;

  const trigger = (
    <Text isHeavy type="body-sm">
      Track
    </Text>
  );
  const children = (
    <div className="flex flex-col gap-2">
      <LoadTracking shipmentId={shipmentId} trackingInfo={trackingInfo} />
    </div>
  );

  return (
    <div className="flex flex-col gap-2">
      <Separator />
      <Accordion
        sections={[{ trigger, children, startOpen: true }]}
        triggerClassName="h-[36px]"
      />
    </div>
  );
}

type LoadTrackingProps = {
  shipmentId: string;
  trackingInfo: {
    driverName?: string;
    driverPhone?: string;
  };
};

function LoadTracking({ shipmentId, trackingInfo }: LoadTrackingProps) {
  const [triggerTrackingProcedure, ctx] = useTriggerTrackingProcedureMutation();
  const { loading } = ctx;
  const { sendToast } = useToast();
  const { track } = useAnalytics();

  async function triggerCheckCallNow() {
    const input = { shipmentId };
    const res = await triggerTrackingProcedure({ variables: { input } });

    const success = res.data?.triggerTrackingProcedure?.success;
    if (success) {
      track('Clicked Do Check Call Now');
      sendToast('Check call triggered', {
        description: 'A check call has been triggered for this shipment.',
        variant: 'success',
      });
    } else {
      track('Failed to Do Check Call Now');
      sendToast('Failed to trigger check call', {
        description: 'Something went wrong triggering the check call.',
        variant: 'error',
      });
    }
  }

  const isDoCheckCallNowDisabled = loading;

  return (
    <div className="flex flex-col gap-1 rounded-md bg-gray-100 p-2">
      <div className="flex flex-row items-center justify-between gap-2">
        <div className="flex flex-row gap-2">
          <TbSteeringWheel size={15} />
          <Text
            isHeavy
            type="body-xs"
            className="line-clamp-1 overflow-hidden text-gray-800"
          >
            {`${trackingInfo.driverName ?? 'Driver'} - ${
              trackingInfo.driverPhone ?? '...'
            }`}
          </Text>
        </div>
        <Tooltip content={`Trigger a check call to the carrier`}>
          <div className="flex flex-row gap-1">
            <Button
              size="xs"
              variant="secondary"
              onPress={() => triggerCheckCallNow()}
              isDisabled={isDoCheckCallNowDisabled}
              icon={<LuPhoneOutgoing />}
              iconPosition="right"
              className="flex flex-row items-center gap-1"
            >
              Check call
            </Button>
          </div>
        </Tooltip>
      </div>
    </div>
  );
}
