import {
  IntegrationType,
  LoadBoardPosting,
  useGetLoadBoardPostingsQuery,
} from '@shared/generated/graphql';

type UseGetLoadBoardPostingsProps = {
  shipmentId: string;
};
export const useGetLoadBoardPostings = ({
  shipmentId,
}: UseGetLoadBoardPostingsProps) => {
  const { data, loading } = useGetLoadBoardPostingsQuery({
    variables: {
      input: { shipmentId, integrationTypes: [IntegrationType.DAT] },
    },
    fetchPolicy: 'network-only',
  });

  const postings =
    data?.getLoadBoardPostings?.postings.reduce(
      (acc, posting) => {
        acc[posting.integrationType as IntegrationType] = posting;
        return acc;
      },
      {} as Record<IntegrationType, LoadBoardPosting>
    ) ?? ({} as Record<IntegrationType, LoadBoardPosting>);

  return {
    postings,
    loading,
  };
};
