import { ApolloError, ApolloQueryResult } from '@apollo/client';
import {
  GetShipmentDetailsQuery,
  SidekickSupportedTms,
  useGetShipmentDetailsQuery,
} from '@shared/generated/graphql';
import { ScheduleStop } from 'clerk_common/types';

type UseShipmentDetailsProps =
  | {
      referenceNumber: string;
      tms?: SidekickSupportedTms;
    }
  | {
      shipmentId: string;
    };

export type ShipmentDetails = {
  shipmentId?: string;
  stops: ScheduleStop[];
  tmsId?: string;
  trackingInfo?: {
    driverName?: string;
    driverPhone?: string;
  };
  isLoading: boolean;
  error?: ApolloError;
  refetch: () => Promise<ApolloQueryResult<GetShipmentDetailsQuery>>;
};

export function useShipmentDetails(
  props: UseShipmentDetailsProps
): ShipmentDetails {
  const variables = { input: props };
  const result = useGetShipmentDetailsQuery({ variables });
  const { data, loading, error, refetch } = result;
  const details = data?.getShipmentDetails;
  const payload = {
    shipmentId: details?.shipmentId,
    stops: toStops(details?.schedulingInfo.stops),
    tmsId: details?.schedulingInfo.tmsId ?? undefined,
    trackingInfo: {
      driverName: details?.trackingInfo.driverName ?? undefined,
      driverPhone: details?.trackingInfo.driverPhone ?? undefined,
    },
  };
  return {
    ...payload,
    isLoading: loading,
    error,
    refetch,
  };
}

// NOTE(jonathan): I'm not sure how to avoid fighting the type system here.
// I realize it's my fault by doing:
// export class ShipmentSchedulingInfoPayload {
//   @Field(() => [GraphQLJSON])    <------ THIS
//   stops!: ScheduleStop[];
// But I do that because I'm frequently changing ScheduleStop and want to avoid
// repeating myself in GraphQL files.

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function toStops(stops?: any[]): ScheduleStop[] {
  if (!stops) return [];
  return stops.map((s) => toStop(s));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function toStop(stop: any): ScheduleStop {
  const ss: ScheduleStop = {
    ...stop,
    portalAvailTimes: toAvailTimes(stop.portalAvailTimes),
  };
  return ss;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function toAvailTimes(times?: any[]): Date[] {
  if (!times) return [];
  return times.map((t) => new Date(t));
}
