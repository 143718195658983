import { ApolloError } from '@apollo/client';
import {
  CarrierSource,
  useGetSuggestedCarriersQuery,
} from '@shared/generated/graphql';

type SuggestedCarrier = {
  carrierName?: string;
  phoneNumber?: string;
  email?: string;
  mcNumber: string;
};

type UseGetSuggestedCarriersProps = {
  shipmentId: string;
};

type UseGetSuggestedCarriersResult = {
  suggestedCarriers: SuggestedCarrier[];
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
};

export const useGetSuggestedCarriers = ({
  shipmentId,
}: UseGetSuggestedCarriersProps): UseGetSuggestedCarriersResult => {
  const { data, loading, error, refetch } = useGetSuggestedCarriersQuery({
    variables: {
      input: { shipmentId, sources: [CarrierSource.GENLOGS] },
    },
  });

  const suggestedCarriers =
    data?.getSuggestedCarriers?.suggestedCarriers.map((carrier) => ({
      carrierName: carrier.carrierName ?? undefined,
      phoneNumber: carrier.phoneNumber ?? undefined,
      email: carrier.email ?? undefined,
      mcNumber: carrier.mcNumber,
    })) ?? [];

  return {
    suggestedCarriers,
    loading,
    error,
    refetch: () => refetch(),
  };
};
