import {
  AddButton,
  RemoveButton,
} from '@shared/components/json-defined-form/arrayComponents/buttons';
import { TextField } from '@shared/components/react-hook-form';
import ReactHookFormSelect, {
  Option,
} from '@shared/components/react-hook-form/ReactHookFormSelect'; // <= your custom select
import {
  ArrayPath,
  Control,
  FieldArray,
  FieldPath,
  FieldValues,
  useFieldArray,
} from 'react-hook-form';

type OptionItem<TType> = TType | { id: TType; label: string };

type ReachHookFormKeyValueArrayProps<
  TFieldValues extends FieldValues,
  TName extends ArrayPath<TFieldValues>,
  TType extends string | number,
> = {
  arrayLabel: string;
  elementLabel: string;
  control: Control<TFieldValues>;
  name: TName;
  keyName: string;
  valueName: string;
  typeOptions: OptionItem<TType>[];
  defaultType: TType;
  isDisabled?: boolean;
  hideLabel?: boolean;
  validate?: (value: string) => boolean | string | undefined;
};

function ReachHookFormKeyValueArray<
  TFieldValues extends FieldValues,
  TName extends ArrayPath<TFieldValues>,
  TType extends string | number,
>({
  arrayLabel,
  elementLabel,
  control,
  name,
  keyName,
  valueName,
  typeOptions,
  defaultType,
  isDisabled,
  hideLabel,
  validate,
}: ReachHookFormKeyValueArrayProps<TFieldValues, TName, TType>) {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <ul className="flex w-full flex-col gap-1">
      {fields.map((field, index) => (
        <li key={field.id} className="flex flex-row flex-wrap items-end gap-2">
          {/* Replace the native select with ReactHookFormSelect */}
          <ReactHookFormSelect<TType, TFieldValues>
            control={control}
            size="small"
            name={`${name}.${index}.${keyName}` as FieldPath<TFieldValues>}
            disabled={isDisabled}
            label={!hideLabel && index === 0 ? arrayLabel : ''}
            className="min-w-[150px]"
            renderValue={(value) => {
              const selectedOption = typeOptions.find(
                (opt) => (typeof opt === 'object' ? opt.id : opt) === value
              );
              return (
                <span>
                  {typeof selectedOption === 'object'
                    ? selectedOption.label
                    : String(selectedOption)}
                </span>
              );
            }}
          >
            {typeOptions.map((opt) => {
              const value = typeof opt === 'object' ? opt.id : opt;
              const label = typeof opt === 'object' ? opt.label : String(opt);

              return (
                <Option key={value} value={value}>
                  {label}
                </Option>
              );
            })}
          </ReactHookFormSelect>

          <TextField
            aria-label={elementLabel}
            name={`${name}.${index}.${valueName}` as FieldPath<TFieldValues>}
            control={control}
            rules={validate ? { validate } : undefined}
            size="small"
            inputWrapperClassName="pr-2 min-w-[150px]"
            wrapperChildrenPlacement="outside"
            isDisabled={isDisabled}
            wrapperChildren={
              <RemoveButton
                removeElement={() => remove(index)}
                elementDescription={elementLabel}
                isDisabled={isDisabled}
              />
            }
          />
        </li>
      ))}
      <AddButton
        isDisabled={isDisabled}
        elementDescription={elementLabel}
        addElement={() =>
          append({
            [keyName]: defaultType,
            [valueName]: '',
          } as FieldArray<TFieldValues, TName>)
        }
      />
    </ul>
  );
}

export default ReachHookFormKeyValueArray;
