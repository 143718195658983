import { Text } from '@shared/components';
import { IntegrationType } from '@shared/generated/graphql';
import { IntegrationConfigs } from '@shared/types/order';
import { Quote } from '@shared/types/quote';

const SHOW_TRANSMIT_CODE_INTEGRATIONS = [IntegrationType.ARCBEST_TL_QUOTING];

export const OriginatorTransmitCode = ({ quote }: { quote: Quote }) => {
  const emptyResult = <div className="mb-4"></div>;
  const transmitIds = quote.originator?.transmitIds;
  const allOriginatorCodes = [
    ...(quote.originator?.externalIdMappings?.map((eim) => eim.externalId) ??
      []),
    ...(transmitIds ?? []),
  ];
  const transmitCodeDetails = allOriginatorCodes?.join(', ');

  if (!transmitCodeDetails) return emptyResult;

  const integrationConfigs = quote.organization
    .integrationConfigs as IntegrationConfigs;
  const integrationTypes = integrationConfigs.integrations.map(
    (config) => config.integrationType
  );

  const showTransmitCode = integrationTypes.some((type) => {
    if (!type) return false;

    return SHOW_TRANSMIT_CODE_INTEGRATIONS.includes(type);
  });

  if (!showTransmitCode) return emptyResult;

  return (
    <div className="mx-4 mb-2 mt-1 flex justify-start overflow-hidden">
      <Text className="truncate text-gray-500" type="body-xs">
        Customer Code
        {allOriginatorCodes?.length && allOriginatorCodes?.length > 1
          ? 's'
          : ''}
        : {transmitCodeDetails}
      </Text>
    </div>
  );
};
