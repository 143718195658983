import { Accordion, Separator, Text } from '@shared/components';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { IntegrationType } from '@shared/generated/graphql';
import { useOrganizationFeatureFlag } from '@shared/hooks/useOrganizationFeatureFlag';
import { IntegrationConfig } from '@shared/types/order';
import { getConfiguredIntegrations } from '@shared/utils/userFeatures/getConfiguredIntegrations';
import { FeatureFlagName } from 'clerk_common/types';
import { CarrierOffers } from '../carrier-offers/CarrierOffers';
import { LoadboardDetails } from '../loadboard-details/LoadboardDetails';
import { SuggestedCarriers } from '../suggested-carriers/SuggestedCarriers';

type VoomaCoverProps = {
  shipmentId: string;
};
export function VoomaCover({ shipmentId }: VoomaCoverProps) {
  const { me, defaultOrgId } = useMeContext();
  const showCarrierOffers = useOrganizationFeatureFlag({
    featureFlagName: FeatureFlagName.CARRIER_OFFERS,
  });

  const configuredIntegrations = getConfiguredIntegrations(
    me,
    defaultOrgId ?? ''
  );
  const showLoadboardDetails =
    countLoadBoardIntegrations(configuredIntegrations) > 0;
  // TODO(mike): Actually check the conditions necessary to show suggested carriers.
  const showSuggestedCarriers = showLoadboardDetails;
  if (!showLoadboardDetails && !showSuggestedCarriers) return null;

  const trigger = (
    <Text isHeavy type="body-sm">
      Cover
    </Text>
  );
  const children = (
    <div className="mt-4 flex flex-col gap-6">
      {showCarrierOffers && <CarrierOffers />}
      {showLoadboardDetails && <LoadboardDetails shipmentId={shipmentId} />}
      {showSuggestedCarriers && <SuggestedCarriers shipmentId={shipmentId} />}
    </div>
  );

  return (
    <div className="flex flex-col gap-2">
      <Separator />
      <Accordion
        sections={[{ trigger, children, startOpen: true }]}
        triggerClassName="h-[36px]"
      />
    </div>
  );
}

function countLoadBoardIntegrations(
  configuredIntegrations: Record<IntegrationType, IntegrationConfig>
) {
  const loadBoardIntegrationTypes = [IntegrationType.DAT];
  return Object.values(configuredIntegrations).filter(
    (integration) =>
      integration.integrationType &&
      loadBoardIntegrationTypes.includes(integration.integrationType)
  ).length;
}
