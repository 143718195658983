import { Quote } from '@shared/types/quote';
import { Location } from 'clerk_common';
import { StandardFreightOrderEquipmentType } from 'clerk_common/enums';
import {
  getAddressFromLocation,
  getDeliveryFromFreightOrder,
  getEquipmentTypesFromFreightOrder,
  getPickupFromFreightOrder,
} from 'clerk_common/templates/freight_order/fieldExtractors/fieldExtractors';
import { getValue } from 'clerk_common/templates/getValue';
import { Address } from 'clerk_common/types/address';

const DAT_LOAD_SEARCH_URL = 'https://one.dat.com/search-loads-ow';
const DAT_RATE_VIEW_URL = 'https://rateview.dat.com/app/';

export const VOOMA_QUERY_PARAM_KEY = 'source';
export const VOOMA_QUERY_PARAM_VALUE = 'vooma';

const locationToDATString = (address: any): string => {
  if (typeof address === 'string') return address;

  const { city, state, zip } = address;

  if (zip) return zip;

  if (city && state) return `${city}, ${state}`;

  return '';
};

export const getPickupAddressFromQuote = (
  quote: Quote
): string | Address | undefined => {
  const pickup = quote.extractedData
    ? getPickupFromFreightOrder(quote.extractedData)
    : undefined;
  if (!pickup?.location) return '';

  const location = getValue(pickup.location) as Location;
  return getAddressFromLocation(location);
};

export const getDestinationAddressFromQuote = (
  quote: Quote
): string | Address | undefined => {
  const destination = quote.extractedData
    ? getDeliveryFromFreightOrder(quote.extractedData)
    : undefined;

  if (!destination?.location) return '';

  const location = getValue(destination.location) as Location;
  return getAddressFromLocation(location);
};

const getDATOriginParam = (quote: Quote): string => {
  const address = getPickupAddressFromQuote(quote);
  return locationToDATString(address);
};

const getDATDestinationParam = (quote: Quote): string => {
  const address = getDestinationAddressFromQuote(quote);
  return locationToDATString(address);
};

const equipmentTypeToDATLoadSearchEquipmentType = (
  equipmentType: StandardFreightOrderEquipmentType
): string => {
  switch (equipmentType) {
    case 'CONTAINER':
      return 'Containers';
    case 'DOUBLE_DROP':
      return 'Double Drop';
    case 'DRY_VAN':
      return 'Van';
    case 'FLATBED':
      return 'Flatbeds';
    case 'HOT_SHOT':
      return 'Van Hotshot';
    case 'LOW_BOY':
      return 'Lowboy';
    case 'POWER_ONLY':
      return 'Power Only';
    case 'REEFER':
      return 'Reefers';
    case 'REMOVABLE_GOOSENECK':
      return 'Removable Gooseneck';
    case 'STEP_DECK':
      return 'Step Deck';
    case 'STRAIGHT_TRUCK':
      return 'Straight Box Truck';
    case 'OTHER':
      return 'Other Equipment';
    default:
      return 'Other Equipment';
  }
};

const equipmentTypeToDATRateViewEquipmentType = (
  equipmentType: StandardFreightOrderEquipmentType
): string => {
  switch (equipmentType) {
    case 'DRY_VAN':
      return 'Van';
    case 'FLATBED':
      return 'Flatbed';
    case 'REEFER':
      return 'Reefer';
    default:
      return 'Van';
  }
};

const getDATRateViewEquipmentParam = (quote: Quote): string => {
  const equipmentTypes = getEquipmentTypesFromFreightOrder(quote.extractedData);

  if (!equipmentTypes?.length) return '';

  const equipmentType = equipmentTypes[0];
  return equipmentTypeToDATRateViewEquipmentType(
    equipmentType as StandardFreightOrderEquipmentType
  );
};

const getDATLoadSearchEquipmentParam = (quote: Quote): string => {
  const equipmentTypes = getEquipmentTypesFromFreightOrder(quote.extractedData);

  if (!equipmentTypes?.length) return '';

  const equipmentType = equipmentTypes[0];
  return equipmentTypeToDATLoadSearchEquipmentType(
    equipmentType as StandardFreightOrderEquipmentType
  );
};

const getBaseVoomaParams = (): URLSearchParams => {
  const params = new URLSearchParams();
  params.set(VOOMA_QUERY_PARAM_KEY, VOOMA_QUERY_PARAM_VALUE);
  return params;
};

const getDATRateViewUrlQueryParams = (quote: Quote): URLSearchParams => {
  const params = getBaseVoomaParams();

  const origin = getDATOriginParam(quote);
  const destination = getDATDestinationParam(quote);
  const equipment = getDATRateViewEquipmentParam(quote);

  equipment && params.set('selEquipmentType', equipment);
  origin && params.set('origin', origin);
  destination && params.set('destination', destination);
  return params;
};

const getDATLoadSearchQueryParams = (quote: Quote): URLSearchParams => {
  const params = getBaseVoomaParams();

  const origin = getDATOriginParam(quote);
  const destination = getDATDestinationParam(quote);
  const equipment = getDATLoadSearchEquipmentParam(quote);

  origin && params.set('origin-automation', origin);
  destination && params.set('destination-automation', destination);
  equipment && params.set('equip-automation', equipment);
  return params;
};

export const getDATRateViewQueryParamString = (quote: Quote): string => {
  return getDATRateViewUrlQueryParams(quote).toString();
};

export const getDATLoadSearchQueryParamString = (quote: Quote): string => {
  return getDATLoadSearchQueryParams(quote).toString();
};

export const getDATRateViewUrl = (quote: Quote): string => {
  const queryString = getDATRateViewQueryParamString(quote);
  return `${DAT_RATE_VIEW_URL}?${queryString}#search`;
};

export const getDATLoadSearchUrl = (quote: Quote): string => {
  const queryString = getDATLoadSearchQueryParamString(quote);
  return `${DAT_LOAD_SEARCH_URL}?${queryString}`;
};
