import { ConversationFragment } from '@shared/generated/graphql';
import { Conversation } from '@shared/types/conversation';
import { basicUserFromFragment } from './user';

export function conversationFromFragment(
  fragment: ConversationFragment
): Conversation {
  return {
    id: fragment.id,
    createdAt: new Date(fragment.createdAt),
    type: fragment.type,
    status: fragment.status,
    user: basicUserFromFragment(fragment.user),
    summary: fragment.summary ?? undefined,
    metadata: fragment.metadata ?? undefined,
    mediaUrl: fragment.mediaUrl ?? undefined,
  };
}
