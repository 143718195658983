import {
  useAddChatMessageToConversationMutation,
  useGetConversationExternalMessagesQuery,
  useGetOrCreateConversationWithConciergeQuery,
} from '@shared/generated/graphql';
import { toConversationEvents } from '@shared/graphql/fromFragments/externalMessage';
import { useConciergeContext } from '@shared/plugin/contexts/ConciergeContext';

type UseChatWithConciergeProps = {
  organizationId: string;
};
export function useChatWithConcierge({
  organizationId,
}: UseChatWithConciergeProps) {
  const { data } = useGetOrCreateConversationWithConciergeQuery({
    variables: { input: { organizationId } },
  });
  const [addChatMessageToConversation, { loading: isAddingMessage }] =
    useAddChatMessageToConversationMutation();

  const sendMessageToConcierge = async (message: string) => {
    if (!data?.getOrCreateConversationWithConcierge.id) {
      throw new Error('Conversation ID not found');
    }
    await addChatMessageToConversation({
      variables: {
        input: {
          conversationId: data?.getOrCreateConversationWithConcierge.id,
          message,
        },
      },
    });
  };
  return {
    conciergeConversationId: data?.getOrCreateConversationWithConcierge.id,
    sendMessageToConcierge,
    isAddingMessage,
  };
}

type UseChatWithConciergeFeedProps = {
  conciergeConversationId: string;
};
export function useChatWithConciergeFeed({
  conciergeConversationId,
}: UseChatWithConciergeFeedProps) {
  const { hasAccessToConcierge, organizationId } = useConciergeContext();
  const shouldPoll = hasAccessToConcierge && conciergeConversationId;
  const { data } = useGetConversationExternalMessagesQuery({
    variables: {
      input: {
        conversationId: conciergeConversationId,
        organizationIds: [organizationId],
      },
    },
    // NOTE(parlato): We should definitely not be polling the external
    // messages table every second
    pollInterval: shouldPoll ? 1 * 1000 : undefined,
  });
  return {
    messages: toConversationEvents(data),
  };
}
