import {
  SubscriptionType,
  UserEmailSubscription,
} from '@shared/generated/graphql';

export type EmailSubscription = {
  id: string;
  type: SubscriptionType;
  isActive: boolean;
  description?: string | null;
};

export const subscriptionFromFragment = (
  fragment: UserEmailSubscription
): EmailSubscription => {
  return {
    id: fragment.id,
    type: fragment.type,
    isActive: fragment.isActive,
    description: fragment.description,
  };
};
