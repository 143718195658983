import { ApolloQueryResult } from '@apollo/client';
import { Button } from '@shared/components';
import { GetShipmentDetailsQuery } from '@shared/generated/graphql';
import { ScheduleStop } from 'clerk_common/types';
import { LiaCalendarSolid } from 'react-icons/lia';
import { EmailStop } from './EmailStop';
import { PortalStop } from './PortalStop';

export type StopProps = {
  stop: ScheduleStop;
  index: number;
  shipmentId: string;
  refetchShipment: () => Promise<ApolloQueryResult<GetShipmentDetailsQuery>>;
};

export function Stop({ stop, index, shipmentId, refetchShipment }: StopProps) {
  const showEmail = stop.placeSchedulePortalType === undefined;
  const showPortal = stop.placeSchedulePortalType !== undefined;

  return (
    <>
      {showEmail && (
        <EmailStop
          stop={stop}
          index={index}
          shipmentId={shipmentId}
          refetchShipment={refetchShipment}
        />
      )}
      {showPortal && (
        <PortalStop
          stop={stop}
          index={index}
          shipmentId={shipmentId}
          refetchShipment={refetchShipment}
        />
      )}
    </>
  );
}

export function ScheduleButton({
  disabled,
  onPress,
}: {
  disabled: boolean;
  onPress: () => void;
}) {
  return (
    <Button
      size="xs"
      variant="secondary"
      icon={<LiaCalendarSolid size={12} />}
      iconPosition="right"
      className="ml-auto"
      isDisabled={disabled}
      onPress={onPress}
    >
      Schedule
    </Button>
  );
}
