import { Text } from '@shared/components';
import { ChatMessageEvent } from '@shared/types/conversation';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import {
  parseToSequence,
  TextOrMentionElement,
} from 'clerk_common/collaboration/mentions/parse';
import clsx from 'clsx';
import { Fragment } from 'react';
import { roleToClassName } from './Transcript';

const ROOT = makeRootClassName('ConversationTranscript');
const el = makeElementClassNameFactory(ROOT);

type ChatContributionProps = {
  contribution: ChatMessageEvent;
};
export const ChatContribution = ({ contribution }: ChatContributionProps) => {
  const sequence = parseToSequence(contribution.message);

  const roleClassName = roleToClassName(contribution.role);
  const className = clsx(el`chat-contribution`, {
    [roleClassName]: true,
  });
  const wrapperClassName = clsx(el`chat-contribution-wrapper`, {
    [roleClassName]: true,
  });

  return (
    <div className={wrapperClassName}>
      <div className={className}>
        <Text type="body-xs">
          {sequence.map((e: TextOrMentionElement, idx: number) => (
            <Fragment key={idx}>
              {e.elementType === 'mention' ? (
                <strong>@{e.display || e.id}</strong>
              ) : (
                e.text
              )}
            </Fragment>
          ))}
        </Text>
      </div>
    </div>
  );
};
