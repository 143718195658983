import { Button, Text } from '@shared/components';
import { RemoveButton } from '@shared/components/json-defined-form/arrayComponents/buttons';
import { TextField } from '@shared/components/react-hook-form';
import { Control, FieldValues, Path } from 'react-hook-form';

type TransmitIdsFormSectionProps<T extends FieldValues> = {
  transmitIdFieldsName: Path<T>;
  transmitIds: string[];
  setValue: (name: Path<T>, value: any) => void;
  control: Control<T>;
  required: boolean;
};
export function TransmitIdsFormSection<T extends FieldValues>(
  p: TransmitIdsFormSectionProps<T>
) {
  const requiredProps = { rules: { required: p.required } };

  const addTransmitId = () => {
    p.setValue(p.transmitIdFieldsName, [...p.transmitIds, '']);
  };

  const removeTransmitId = (index: number) => {
    p.setValue(
      p.transmitIdFieldsName,
      p.transmitIds.filter((_, i) => i !== index)
    );
  };

  return (
    <div className="flex w-full flex-col items-end gap-2">
      <div className="mb-2">
        <Text type="body-md">What are the customer codes?</Text>
        <Text type="body-xs" className="text-gray-500">
          These are used in integrations to identify your customers.
        </Text>
      </div>
      {p.transmitIds.map((_, i, arr) => {
        return (
          <div
            className="flex w-full flex-row items-center gap-2"
            key={`${i}-of-${arr.length}`}
          >
            <TextField
              name={`${p.transmitIdFieldsName}.${i}` as Path<T>}
              control={p.control}
              size="small"
              {...requiredProps}
              aria-label="Customer Code"
            />
            <RemoveButton
              removeElement={() => {
                removeTransmitId(i);
              }}
              elementDescription={`Customer code ${i + 1}`}
              aria-label="Remove Customer Code"
            />
          </div>
        );
      })}
      <Button size="small" variant="tertiary" onPress={addTransmitId}>
        {p.transmitIds.length === 0
          ? '+ Add a Customer Code'
          : '+ Add Another Customer Code'}
      </Button>
    </div>
  );
}
