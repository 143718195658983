import { createContext } from 'react';

export type BaseUrlContextType = {
  url: string;
};

export const BaseUrlContext = createContext<BaseUrlContextType>({
  url: '',
});

export const BaseUrlContextProvider = ({
  children,
  url,
}: {
  children: JSX.Element;
  url: string;
}) => {
  return (
    <BaseUrlContext.Provider
      value={{
        url,
      }}
    >
      {children}
    </BaseUrlContext.Provider>
  );
};
