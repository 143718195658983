import { AvailabilityCheckFields } from '@shared/generated/graphql';
import { SchedulePortalType } from 'clerk_common/enums';
import {
  AvailabilityInputFields,
  OpendockAvailabilityInputFields,
} from 'clerk_common/types';
import { OpendockFields } from './Opendock';

type CustomFieldsProps = {
  type: SchedulePortalType;
  fields: AvailabilityCheckFields;
  handleFieldsChange: (i: AvailabilityInputFields) => Promise<void>;
  setInputFields: (i: OpendockAvailabilityInputFields) => void;
};

export function CustomFields({
  type,
  fields,
  handleFieldsChange,
  setInputFields,
}: CustomFieldsProps) {
  switch (type) {
    case SchedulePortalType.OPENDOCK:
      return (
        <OpendockFields
          fields={fields}
          handleFieldsChange={handleFieldsChange}
          setInputFields={setInputFields}
        />
      );
    default:
      return null;
  }
}
