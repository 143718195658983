import { Text } from '@shared/components';
import { CircularProgress } from '@shared/components/plugin-components';
import { ShipmentCard } from '@shared/features/shipment-card/ShipmentCard';
import {
  ShipmentDetails,
  useShipmentDetails,
} from '@shared/hooks/useShipmentDetails';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { useParams } from 'react-router-dom';
import ShipmentSidePanelHeader from './ShipmentSidePanelHeader';

const ROOT = makeRootClassName('ShipmentSidePanel');
export const el = makeElementClassNameFactory(ROOT);

type ShipmentSidePanelProps = {
  webBaseUrl: string;
};
export function ShipmentSidePanel({ webBaseUrl }: ShipmentSidePanelProps) {
  const { shipmentId } = useParams();

  const info = useShipmentDetails({ shipmentId: shipmentId ?? '' });

  return (
    <div className={el`shipment-container`}>
      <div className={el`shipment-header`}>
        <ShipmentSidePanelHeader
          webBaseUrl={webBaseUrl}
          shipmentId={shipmentId || ''}
        />
      </div>
      <ShipmentCardContainer info={info} />
    </div>
  );
}

function ShipmentLoading() {
  return <CircularProgress size="sm" />;
}

type ShipmentCardContainerProps = {
  info: ShipmentDetails;
};
function ShipmentCardContainer({ info }: ShipmentCardContainerProps) {
  if (info.isLoading) return <ShipmentLoading />;
  if (info.error || !info.shipmentId) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-2">
        <Text type="body-sm" className="p-2">
          {"Vooma doesn't have a record of this shipment."}
        </Text>
      </div>
    );
  }

  const { shipmentId, stops, tmsId } = info;
  return (
    <ShipmentCard
      referenceNumber={tmsId || ''}
      shipmentId={shipmentId}
      stops={stops}
      tmsId={tmsId}
      trackingInfo={info.trackingInfo ?? {}}
      refetchShipment={info.refetch}
    />
  );
}
