import { AutocompleteBase } from '@shared/components/autocomplete';
import { TextField } from '@shared/components/react-hook-form';
import { Text } from '@shared/components/text';
import { Tooltip } from '@shared/components/tooltip';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { enumToString } from 'clerk_common/enums';
import { RateComponentType } from 'clerk_common/types/pricingStrategy';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { MoneyFormValues } from '../types';

const ROOT = makeRootClassName('RateLineItem');
const el = makeElementClassNameFactory(ROOT);
const IS_AUTOCOMPLETE = false;

type RateLineItemProps = {
  idx: number;
  expanded: boolean;
  type: RateComponentType;
  submitForm: () => void;
};

export const RateLineItem = ({
  idx,
  type,
  expanded,
  submitForm,
}: RateLineItemProps) => {
  const { control, watch } = useFormContext<MoneyFormValues>();
  const lineItem = watch(`lineItems.${idx}`);
  const isFirstLineItem = idx === 0;

  // NOTE(parlato): Need to investigate why this was once undefined
  if (!lineItem) {
    return null;
  }

  const onBlur = () => {
    if (expanded) {
      submitForm();
    }
  };

  const { description } = lineItem;

  // TODO(parlato): Implement
  const options = Object.values(RateComponentType).map((type) => ({
    id: type,
    label: enumToString(type),
  }));
  return (
    <div className={el`line-item-container`}>
      <Tooltip content={description}>
        <div className={clsx(el`description-cell`)}>
          {IS_AUTOCOMPLETE ? (
            <AutocompleteBase
              focusedInputWrapperClassName={el`autocomplete-input-wrapper`}
              className={el`autocomplete`}
              value={options.find((o) => o.id === type)}
              transparentWithoutFocus
              options={options}
              size="xs"
              inputWrapperClassName={clsx(el`line-item-text-field`)}
            />
          ) : (
            <TextField
              autoFocus={Boolean(!description)}
              name={`lineItems.${idx}.description`}
              control={control}
              isRequired
              validationState={description ? undefined : 'invalid'}
              transparentWithoutFocus
              size="small"
              value={description}
              placeholder="Add name..."
              onBlur={onBlur}
              inputWrapperClassName={clsx(el`line-item-text-field`)}
              fieldWrapperClassName={el`line-item-field-wrapper`}
            />
          )}
        </div>
      </Tooltip>
      <div className={el`text-field-container`}>
        {isFirstLineItem && (
          <Text type="body-xs" isHeavy className={el`text-field-label`}>
            Buy rate
          </Text>
        )}
        <TextField
          size="small"
          onBlur={onBlur}
          name={`lineItems.${idx}.buyRate`}
          control={control}
          inputWrapperClassName={clsx(el`line-item-text-field`)}
          fieldWrapperClassName={el`line-item-field-wrapper`}
          prepend={
            <Text type="body-xs" className={el`dollars-prepend`}>
              $
            </Text>
          }
          isDisabled={!description}
        />
      </div>
      <div className={el`text-field-container`}>
        {isFirstLineItem && (
          <Text type="body-xs" isHeavy className={el`text-field-label`}>
            Sell rate
          </Text>
        )}
        <TextField
          size="small"
          name={`lineItems.${idx}.sellRate`}
          control={control}
          onBlur={onBlur}
          inputWrapperClassName={clsx(el`line-item-text-field`)}
          fieldWrapperClassName={el`line-item-field-wrapper`}
          prepend={
            <Text type="body-xs" className={el`dollars-prepend`}>
              $
            </Text>
          }
          isDisabled={!description}
        />
      </div>
    </div>
  );
};
