import { Text } from '@shared/components/text';
import { RateMetadata } from '@shared/types/quote';
import { usdFormatter } from 'clerk_common/stringification/numbers';
import moment from 'moment';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  DATRate,
  DATYearMonth,
} from './rating-widgets/components/dat-more-info/types';

type DATRateHistoryRate = DATRate & DATYearMonth;

const getTooltipContent = (rate: DATRateHistoryRate) => {
  const monthName = moment(rate.month, 'M').format('MMMM');
  const amount = rate.perTrip.rateUsd + rate.averageFuelSurchargePerTripUsd;
  const rateUsd = usdFormatter.format(amount);
  return `${monthName} ${rate.year} - ${rateUsd}`;
};

const getRateMonthAbbr = (rate: DATRateHistoryRate) => {
  return moment(rate.month, 'M').format('MMM');
};

const CustomTooltip = (props: any) => {
  if (!props.active) return null;

  const data = props.payload[0].payload as { tooltip: string };

  return (
    <div className="gap-1 rounded-md bg-white px-3 py-1 shadow-md">
      <Text type="body-xs">{data.tooltip}</Text>
    </div>
  );
};

const DATRateHistory = ({ rates }: { rates: DATRateHistoryRate[] }) => {
  const chartData = rates.map((rate) => ({
    tooltip: getTooltipContent(rate),
    value: rate.perTrip.rateUsd + rate.averageFuelSurchargePerTripUsd,
    date: getRateMonthAbbr(rate),
  }));

  return (
    <div className="mt-2 flex h-16 w-full flex-row justify-center">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={chartData}>
          <XAxis dataKey="date" tickSize={4} fontSize={10} />
          <YAxis tickSize={4} fontSize={10} orientation="left" width={28} />
          <Tooltip position={{ y: -40 }} content={<CustomTooltip />} />
          <Line type="monotone" dataKey="value" stroke="#F97316" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export const DATRateWidget = ({ metadata }: { metadata: RateMetadata }) => {
  const rates = metadata?.response?.rates as DATRateHistoryRate[];
  const isHistory = Boolean(rates?.length);
  return isHistory ? <DATRateHistory rates={rates} /> : null;
};
