import {
  ChatMessageEvent,
  ConversationEvent,
  ConversationEventType,
} from '@shared/types/conversation';
import { ChatMessageFeedItem, FeedItemType } from '../types';

export const transformChatConversationEvents = (
  events: ConversationEvent[]
): ChatMessageFeedItem[] => {
  const filteredEvents = events.filter(
    (event) => event.type === ConversationEventType.CHAT_MESSAGE
  );
  return filteredEvents.map((event) => {
    return {
      type: FeedItemType.CHAT_MESSAGE,
      createdAt: event.createdAt.toISOString(),
      message: event as ChatMessageEvent,
    };
  });
};
