import { Text } from '@shared/components/text';
import { RatingMethod } from '@shared/generated/graphql';
import { RateMetadata } from '@shared/types/quote';
import { makeRootClassName } from '@shared/utils';
import { enumToString } from 'clerk_common/enums';
import { DATEscalationLocation, DATRateResponse } from './types';

const ROOT = makeRootClassName('DATMoreInfo');

const extractDATCompaniesAndReports = (response: DATRateResponse) => {
  const companies = response.rate.companies;
  const reports = response.rate.reports;

  return `${companies} companies | ${reports} reports`;
};

const extractDATMarket = (location: DATEscalationLocation) => {
  return `${location.name} (${enumToString(location.type)})`;
};

const extractDATMarketsInformation = (response: DATRateResponse) => {
  const { escalation } = response;

  if (!escalation) return null;

  return (
    <div>
      <Text type="custom">
        <strong>Origin:</strong> {extractDATMarket(escalation.origin)}
      </Text>
      <Text type="custom">
        <strong>Destination:</strong> {extractDATMarket(escalation.destination)}
      </Text>
    </div>
  );
};

export const getMoreInfo = ({
  metadata,
  ratingMethod,
}: {
  metadata?: RateMetadata;
  ratingMethod?: RatingMethod;
}) => {
  if (!metadata || !ratingMethod) return null;

  switch (ratingMethod) {
    case RatingMethod.DAT:
      if (metadata?.ratingMethod === 'HISTORY') return null;
      return <DATRateMoreInfo metadata={metadata} />;
    default:
      return null;
  }
};

export const DATRateMoreInfo = ({ metadata }: { metadata?: RateMetadata }) => {
  if (!metadata) return null;

  return (
    <div className={ROOT}>
      <div>{extractDATMarketsInformation(metadata.response)}</div>
      <Text type="custom">
        {extractDATCompaniesAndReports(metadata.response)}
      </Text>
    </div>
  );
};
