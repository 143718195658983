import { useToast } from '@shared/components/toast';
import { LoadShipmentForm } from '@shared/features/load-shipment-form';
import {
  SidekickSupportedTms,
  useGetShipmentDetailsLazyQuery,
} from '@shared/generated/graphql';
import { useOrganizationFeatureFlag } from '@shared/hooks/useOrganizationFeatureFlag';
import { SHIPMENTS_ROUTE } from 'clerk_common/constants/urls/routes';
import { FeatureFlagName } from 'clerk_common/types';
import { useNavigate } from 'react-router-dom';

export const NavigateToShipment = () => {
  const { sendToast } = useToast();
  const navigate = useNavigate();
  const [getShipmentDetails] = useGetShipmentDetailsLazyQuery();

  const isLoadShipmentByIdEnabled = useOrganizationFeatureFlag({
    featureFlagName: FeatureFlagName.LOAD_SHIPMENT_BY_ID,
  });

  if (!isLoadShipmentByIdEnabled) return null;

  const loadShipmentByReference = (referenceNumber: string) => {
    const sendErrorToast = () => {
      sendToast('Unable to find shipment', {
        variant: 'error',
        autoHideDuration: 3000,
        isDismissible: true,
      });
    };

    getShipmentDetails({
      variables: {
        input: {
          referenceNumber,
          tms: SidekickSupportedTms.UNKNOWN,
        },
      },
    })
      .then((result) => {
        const shipmentId = result.data?.getShipmentDetails.shipmentId;
        if (shipmentId) {
          navigate(
            `${SHIPMENTS_ROUTE}/${result.data?.getShipmentDetails.shipmentId}`
          );
        } else {
          sendErrorToast();
        }
      })
      .catch(sendErrorToast);
  };

  return (
    <div className="flex justify-center bg-white">
      <LoadShipmentForm setShipmentReference={loadShipmentByReference} />
    </div>
  );
};
