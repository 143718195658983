import {
  useGetUsersLazyQuery,
  useGetUsersQuery,
} from '@shared/generated/graphql';
import { toName, User } from '@shared/types/user';

type UseGetUsersOptions = {
  organizationIds?: string[];
  searchTerm?: string;
  pollIntervalMs?: number;
  emails?: string[];
  skip?: boolean;
};

type UseGetUsersState = {
  users: User[];
  isLoading: boolean;
};

type UseGetUsersActions = {
  // add any actions the hook provides
};

export type UseGetUsersValue = UseGetUsersState & UseGetUsersActions;

export const useGetUsers = (props?: UseGetUsersOptions): UseGetUsersValue => {
  const { data, loading } = useGetUsersQuery({
    variables: {
      input: {
        organizationIds: props?.organizationIds,
        searchTerm: props?.searchTerm,
        emails: props?.emails,
      },
    },
    skip: props?.skip,
    ...(props?.pollIntervalMs && { pollInterval: props.pollIntervalMs }),
  });

  if (!data) return { users: [], isLoading: loading };

  const users = data.users.edges.map((user) => ({
    id: user.node.id,
    userName: user.node.username ?? '',
    name: toName(user.node),
    email: user.node.email ?? undefined,
    roles: user.node.roles,
  }));

  return {
    users: users,
    isLoading: loading,
  };
};

type UseFetchUsersOptions = {
  organizationIds: string[];
};

export const useFetchUsers = (p: UseFetchUsersOptions) => {
  const [getUsers] = useGetUsersLazyQuery();

  const fetchUsers = async (searchTerm: string) => {
    const { data } = await getUsers({
      variables: {
        input: {
          organizationIds: p.organizationIds,
          searchTerm: searchTerm,
        },
      },
    });
    if (!data) return [];
    return data.users.edges.map((user) => ({
      id: user.node.id,
      userName: user.node.username ?? '',
      name: toName(user.node),
      email: user.node.email ?? undefined,
      roles: user.node.roles,
    }));
  };

  return { fetchUsers };
};
