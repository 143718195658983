import { ApolloError, ApolloQueryResult } from '@apollo/client';
import {
  AvailabilityCheck,
  Exact,
  GetSchedulePortalAvailabilityCheckInput,
  GetSchedulePortalAvailabilityCheckQuery,
  useGetSchedulePortalAvailabilityCheckQuery,
} from '@shared/generated/graphql';

type Props = {
  shipmentId: string;
  index: number;
};

type QueryResult = {
  check?: AvailabilityCheck;
  loading: boolean;
  error?: ApolloError;
  refetch: (
    variables?:
      | Partial<Exact<{ input: GetSchedulePortalAvailabilityCheckInput }>>
      | undefined
  ) => Promise<ApolloQueryResult<GetSchedulePortalAvailabilityCheckQuery>>;
};

export function useGetAvailabilityCheck(props: Props): QueryResult {
  const { shipmentId, index: stopsIndex } = props;
  const fields = {};
  const variables = { input: { shipmentId, stopsIndex, fields } };
  const result = useGetSchedulePortalAvailabilityCheckQuery({ variables });
  const { data, loading, error, refetch } = result;
  const check = data?.getSchedulePortalAvailabilityCheck.check;
  return { check, loading, error, refetch };
}
