import { useConciergeContext } from '@shared/plugin/contexts/ConciergeContext';
import { useState } from 'react';
import { MentionInput } from '../mention-input/MentionInput';

function ChatInput() {
  const { sendMessageToConcierge, loading } = useConciergeContext();

  const [newMessage, setNewMessage] = useState('');

  function handleSubmit() {
    // TODO(mike): Consider dispatching messages based on who is tagged.
    sendMessageToConcierge(newMessage);
    setNewMessage('');
  }
  return (
    <>
      <MentionInput
        value={newMessage}
        onChange={setNewMessage}
        submit={{
          onSubmit: handleSubmit,
          loading,
          isDisabled: false,
        }}
      />
    </>
  );
}

export default ChatInput;
