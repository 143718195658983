import { Text } from '@shared/components';
import { ActionTimelineEvent } from '@shared/components/events-timeline/components/action-timeline-event';
import { InlineTextField } from '@shared/components/inline-text-field/InlineTextField';
import { useToast } from '@shared/components/toast';
import {
  AppointmentStatus,
  TriggerEmailAppointmentSchedulingMutation,
  UpdatedPlace,
  useTriggerEmailAppointmentSchedulingMutation as useScheduleEmail,
} from '@shared/generated/graphql';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ScheduleButton, StopProps } from './Stop';

const ROOT = makeRootClassName('ShipmentCard');
const el = makeElementClassNameFactory(ROOT);

type StopForm = {
  email: string;
};

export function EmailStop({
  stop,
  index,
  shipmentId,
  refetchShipment,
}: StopProps) {
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(true);
  const options = { onCompleted: onScheduleCompleted };
  const [triggerScheduling] = useScheduleEmail(options);
  const { sendToast } = useToast();
  const defaultValues: StopForm = { email: stop.placePrimaryEmail ?? '' };
  const methods = useForm<StopForm>({ defaultValues });

  async function scheduleStop() {
    const primaryEmail = methods.getValues('email');
    const placesToUpdate = toPlacesToUpdate(stop.placeId, primaryEmail);
    const stopsIndexesToSchedule = [index];
    const input = { shipmentId, stopsIndexesToSchedule, placesToUpdate };
    setSubmitEnabled(false);
    await triggerScheduling({ variables: { input } });
  }

  async function onScheduleCompleted(
    data: TriggerEmailAppointmentSchedulingMutation
  ) {
    const { success, error } = data.triggerEmailAppointmentScheduling;
    if (!success && error) {
      sendToast(error);
    }
    if (success) {
      await refetchShipment();
    }
    setSubmitEnabled(true);
  }

  const status = stop.appointmentStatus;
  const isNotYetScheduled = notYetScheduledOrUnknown(status);
  const isInProgress = knownAndInProgress(status);

  return (
    <FormProvider {...methods}>
      <ActionTimelineEvent type={stop.stopType}>
        <div className="mb-4 ml-2 mt-1">
          <Text isHeavy type="body-xs">
            {stop.locationLabel}
          </Text>
          <Text type="body-xs">{stop.address.addressOne}</Text>
          {isNotYetScheduled && (
            <>
              <div className="my-3 rounded-md border bg-gray-50 p-2">
                <Text type="body-xs" isHeavy>
                  Contact
                </Text>
                <InlineEmailField />
              </div>
              <div>
                <ScheduleButton
                  onPress={scheduleStop}
                  disabled={!submitEnabled}
                />
              </div>
            </>
          )}
          {isInProgress && (
            <div className="mt-1">
              <div className={`${el`appt-status-chip`} status-${status!}`}>
                Appointment {titleCase(status!)}
              </div>
            </div>
          )}
        </div>
      </ActionTimelineEvent>
    </FormProvider>
  );
}

function InlineEmailField() {
  return (
    <InlineTextField
      name="email"
      label="Email"
      type="text"
      save={() => {}}
      required={false}
    />
  );
}

function toPlacesToUpdate(
  placeId: string | undefined | null,
  primaryEmail?: string
): UpdatedPlace[] {
  if (!placeId) return [];
  const place: UpdatedPlace = {
    id: placeId,
    primaryEmail: primaryEmail ?? '',
  };
  return [place];
}

function notYetScheduledOrUnknown(status?: AppointmentStatus) {
  if (!status) return true;
  if (status == AppointmentStatus.NEW) return true;
  if (status == AppointmentStatus.REQUESTED) return false;
  if (status == AppointmentStatus.CONFIRMED) return false;
  return true;
}

function knownAndInProgress(status?: AppointmentStatus) {
  if (!status) return false;
  if (status == AppointmentStatus.REQUESTED) return true;
  if (status == AppointmentStatus.CONFIRMED) return true;
  return false;
}

function titleCase(str: string) {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
