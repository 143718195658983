import { ApolloQueryResult } from '@apollo/client';
import { Accordion, EventsTimeline, Separator, Text } from '@shared/components';
import { GetShipmentDetailsQuery } from '@shared/generated/graphql';
import { ScheduleStop } from 'clerk_common/types';
import { Stop } from './Stops/Stop';

type VoomaScheduleProps = {
  shipmentId: string;
  stops: ScheduleStop[];
  refetchShipment: () => Promise<ApolloQueryResult<GetShipmentDetailsQuery>>;
};
export function VoomaSchedule({
  shipmentId,
  stops,
  refetchShipment,
}: VoomaScheduleProps) {
  const trigger = (
    <Text type="body-sm" isHeavy>
      Schedule
    </Text>
  );
  const children = (
    <div className="flex flex-col gap-2">
      <EventsTimeline>
        {stops.map((stop, i) => (
          <Stop
            key={i}
            stop={stop}
            index={i}
            shipmentId={shipmentId}
            refetchShipment={refetchShipment}
          />
        ))}
      </EventsTimeline>
    </div>
  );
  return (
    <div className="flex flex-col gap-2">
      <Separator />
      <Accordion
        sections={[{ trigger, children, startOpen: true }]}
        triggerClassName="h-[36px]"
      />
    </div>
  );
}
