import { Button, Text } from '@shared/components';
import { AvailabilityCheckFields } from '@shared/generated/graphql';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { SchedulePortalType } from 'clerk_common/enums';
import {
  AvailabilityInputFields,
  OpendockAvailabilityInputFields,
} from 'clerk_common/types';
import moment from 'moment-timezone';
import { CustomFields } from './Portals/CustomFields';

const ROOT = makeRootClassName('ShipmentCard');
const el = makeElementClassNameFactory(ROOT);

type PortalProps = {
  type: SchedulePortalType;
  times: Date[];
  timeZone: string;
  selectedDate: Date | null;
  setSelectedDate: (d: Date | null) => void;
  info?: string;
  fields?: AvailabilityCheckFields;
  handleFieldsChange: (i: AvailabilityInputFields) => Promise<void>;
  setInputFields: (i: OpendockAvailabilityInputFields) => void;
};

export function Portal({
  type,
  times,
  timeZone,
  selectedDate,
  setSelectedDate,
  fields,
  handleFieldsChange,
  setInputFields,
}: PortalProps) {
  const timesEachDay = groupDatesByDay(times, timeZone);

  return (
    <div>
      <Text type="body-xs" isHeavy>
        {toPortalLabel(type)}
      </Text>
      {fields && (
        <CustomFields
          type={type}
          fields={fields}
          handleFieldsChange={handleFieldsChange}
          setInputFields={setInputFields}
        />
      )}
      <div className="flex flex-col gap-4">
        {timesEachDay.map((times, i) => (
          <Day
            key={i}
            availableTimes={times}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            timeZone={timeZone}
          />
        ))}
      </div>
    </div>
  );
}

type DayProps = {
  availableTimes: Date[];
  selectedDate: Date | null;
  setSelectedDate: (d: Date | null) => void;
  timeZone: string;
};

function Day({
  availableTimes,
  selectedDate,
  setSelectedDate,
  timeZone,
}: DayProps) {
  function handleTimeClick(date: Date) {
    if (date == selectedDate) {
      setSelectedDate(null);
    } else {
      setSelectedDate(date);
    }
  }
  const firstTime = availableTimes[0];
  const localDay = moment(firstTime).tz(timeZone);
  const dayFormatted = localDay.format('dddd, MMM D');
  const tzAbbr = localDay.format('z');

  return (
    <div className="">
      <Text type="body-sm">
        {dayFormatted} ({tzAbbr})
      </Text>
      <div className="flex flex-wrap gap-2">
        {availableTimes.map((date, i) => (
          <Time
            key={i}
            time={date}
            isSelected={date == selectedDate}
            onClick={() => {
              handleTimeClick(date);
            }}
            timeZone={timeZone}
          />
        ))}
      </div>
    </div>
  );
}

type TimeProps = {
  time: Date;
  timeZone: string;
  isSelected: boolean;
  onClick: () => void;
};

function Time({ isSelected, onClick, time, timeZone }: TimeProps) {
  const variant = isSelected ? 'primary' : 'default';
  const localDatetime = moment(time).tz(timeZone);
  return (
    <Button
      size="small"
      variant={variant}
      className={el`portal-time-chip`}
      onPress={onClick}
    >
      {localDatetime.format('h:mma')}
    </Button>
  );
}

function toPortalLabel(type: SchedulePortalType) {
  return titleCase(type as string);
}

function titleCase(str: string) {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

function groupDatesByDay(dates: Date[], timezone: string): Date[][] {
  const grouped: Record<string, Date[]> = {};

  dates.forEach((date) => {
    const dayKey = moment(date).tz(timezone).format('YYYY-MM-DD');
    if (!grouped[dayKey]) {
      grouped[dayKey] = [];
    }
    grouped[dayKey].push(date);
  });

  return Object.values(grouped);
}
