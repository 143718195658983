import {
  GetOriginatorsQuery,
  IntegrationType,
  OriginatorFragment,
} from '@shared/generated/graphql';
import { ConfigOverrides } from '@shared/types/order';
import { Originator } from '../originator';

export const originatorFromFragment = (
  fragment: OriginatorFragment
): Originator => {
  return {
    id: fragment.id,
    transmitIds: fragment.transmitIds,
    name: fragment.name,
    createdAt: new Date(fragment.createdAt),
    status: fragment.status,
    autoSubmitScope: fragment.autoSubmitScope,
    organization: {
      id: fragment.organization.id,
      name: fragment.organization.name,
      type: fragment.organization.type ?? undefined,
    },
    knownSenders: fragment.knownSenders.map((sender) => ({
      id: sender.id,
      email: sender.email ?? 'unknown email',
    })),
    externalIdMappings: (fragment?.externalIdMappings ?? []).map((eim) => ({
      id: eim.id,
      integrationType: eim.integrationType as IntegrationType,
      externalId: eim.externalId,
    })),
    expectedWeeklyOrderCount: fragment.expectedWeeklyOrderCount ?? undefined,
    configOverrides: (fragment.configOverrides as ConfigOverrides) ?? undefined,
    flagged: fragment.flagged,
    hasExtractedFieldsConfigured: fragment.hasExtractedDataConfigured,
  };
};

export const originatorsFromFragment = (
  query?: GetOriginatorsQuery
): Originator[] => {
  return (
    query?.originators.edges.map(({ node }) => originatorFromFragment(node)) ||
    []
  );
};
