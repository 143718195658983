import { Button } from '@shared/components';
import { Menu } from '@shared/plugin/features/Menu';
import { GoArrowLeft } from 'react-icons/go';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

interface LayoutProps {
  children: React.ReactNode;
  hasMenu?: boolean;
  webBaseUrl: string;
}

export const Layout = ({ hasMenu, children, webBaseUrl }: LayoutProps) => {
  const navigate = useNavigate();

  return (
    <div className="text-body-md flex h-screen flex-col overflow-hidden">
      <div className="flex flex-1 flex-col overflow-hidden">
        <div className="z-10 flex flex-row items-center justify-between bg-white p-2 text-gray-700">
          {hasMenu ? (
            <Menu />
          ) : (
            <Button
              onPress={() => navigate(-1)}
              variant="secondary"
              size="xs"
              icon={<GoArrowLeft size={12} />}
            >
              Back
            </Button>
          )}

          <a href={webBaseUrl} target="_blank" rel="noreferrer">
            <Button
              size="xs"
              icon={<MdOutlineOpenInNew className="text-brand-500" />}
              tooltip="Open Vooma web app"
              variant="secondary"
              iconPosition="right"
            >
              Vooma
            </Button>
          </a>
        </div>
        <div className="flex flex-1 flex-col overflow-y-hidden bg-gray-50">
          {children}
        </div>
      </div>
    </div>
  );
};
