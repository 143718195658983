import { Me } from '@shared/contexts/hooks/useMe';
import { IntegrationType } from '@shared/generated/graphql';
import { IntegrationConfig } from '@shared/types/order';

function getOrgDetails(me: Me, organizationId: string) {
  return me?.organizationsDetails?.find((org) => org.id === organizationId);
}

export function getConfiguredIntegrations(
  me: Me,
  organizationId: string
): Record<IntegrationType, IntegrationConfig> {
  const organizationDetails = getOrgDetails(me, organizationId);
  const integrations: IntegrationConfig[] =
    organizationDetails?.integrationConfigs?.integrations || [];
  return integrations.reduce(
    (acc, integration) => {
      if (!integration.integrationType) return acc;
      acc[integration.integrationType] = integration;
      return acc;
    },
    {} as Record<IntegrationType, IntegrationConfig>
  );
}
