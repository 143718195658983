import { CircularProgress } from '@shared/components';
import { Button } from '@shared/components/button';
import ReactHookFormTextField from '@shared/components/react-hook-form/ReactHookFormTextField';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';

const ROOT = makeRootClassName('LoadShipmentForm');
const el = makeElementClassNameFactory(ROOT);

interface LoadShipmentFormValues {
  shipmentReference: string;
}

interface LoadShipmentFormProps {
  onSubmit: (data: LoadShipmentFormValues) => void;
  isLoading?: boolean;
}

function LoadShipmentForm(props: LoadShipmentFormProps): ReactElement {
  const { control, handleSubmit } = useForm<LoadShipmentFormValues>({
    defaultValues: {
      shipmentReference: '',
    },
  });

  const submitHandler = (data: LoadShipmentFormValues) => {
    props.onSubmit(data);
  };

  return (
    <div className={clsx(ROOT)}>
      <form onSubmit={handleSubmit(submitHandler)} className={el`form`}>
        <ReactHookFormTextField
          name="shipmentReference"
          size="small"
          placeholder="Load a shipment by ID"
          control={control}
        />
        <Button
          type="submit"
          size="small"
          variant="primary"
          isDisabled={props.isLoading}
          className="h-[32px] rounded-lg"
          icon={props.isLoading ? <CircularProgress size="xs" /> : undefined}
        >
          Load
        </Button>
      </form>
    </div>
  );
}

type LoadShipmentFormContainerProps = {
  setShipmentReference: (referenceNumber: string) => void;
};

function LoadShipmentFormContainer(props: LoadShipmentFormContainerProps) {
  const onSubmit = (values: LoadShipmentFormValues) => {
    props.setShipmentReference(values.shipmentReference);
  };
  const isLoading = false;
  return <LoadShipmentForm onSubmit={onSubmit} isLoading={isLoading} />;
}

export default LoadShipmentFormContainer;
