import { InteractableComponent } from '@shared/components/interactable-component';
import { Text } from '@shared/components/text';
import { RatingMethod } from '@shared/generated/graphql';
import { intersperseElements } from '@shared/plugin/utils/intersperseElements';
import { RateMetadata } from '@shared/types/quote';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import {
  getMileageRate,
  makeRateDetailSubtitleComponents,
  RateDetailSubtitleType,
  shouldShowRateValue,
} from '@shared/utils/rates/rateDetails';
import { usdFormatterNoCents } from 'clerk_common/stringification/numbers';
import { isNil } from 'lodash';
import { getRateConfidence, RateWidget } from '../RateWidgets';
import { getMoreInfo } from '../rating-widgets/components/dat-more-info/DATMoreInfo';

const ROOT = makeRootClassName('RateDetail');
const el = makeElementClassNameFactory(ROOT);

type RateDetailProps = {
  rate: number;
  ratingMethod?: RatingMethod;
  metadata?: RateMetadata;
  label: string;
  onPress: () => void;
  isDisabled?: boolean;
};

export const RateDetail = ({
  rate,
  ratingMethod,
  metadata,
  label,
  onPress,
  isDisabled,
}: RateDetailProps) => {
  const mileageRate = getMileageRate({ metadata });

  const rateConfidence = getRateConfidence({ metadata, ratingMethod });

  return (
    <div className={ROOT}>
      <InteractableComponent onPress={onPress} isDisabled={isDisabled}>
        <div className={el`rate-container`}>
          <div className={el`rate-detail`}>
            <div className={el`rate-info`}>
              <div className={el`rate-label`}>
                <Text type="body-xs">{label}</Text>
              </div>
              {shouldShowRateValue({ metadata }) && (
                <div className={el`rate-value`}>
                  <Text isHeavy type="body-xs">
                    {usdFormatterNoCents.format(rate)}
                  </Text>
                  <Text className={el`mileage-rate`} type="custom">
                    {mileageRate}
                  </Text>
                </div>
              )}
            </div>
            {!isNil(rateConfidence) && (
              <div className={el`rate-confidence`}>{rateConfidence}</div>
            )}
          </div>
          <div className={el`rate-detail-section`}>
            <RateWidget ratingMethod={ratingMethod} metadata={metadata} />
          </div>
          <div className={el`rate-detail-section`}>
            <Text type="custom" className={el`rate-subtitle`}>
              {intersperseElements(
                makeRateDetailSubtitleComponents({
                  ratingMethod,
                  metadata,
                  type: RateDetailSubtitleType.RATE,
                }),
                'custom'
              )}
            </Text>
            <Text type="custom" className={el`rate-subtitle`}>
              {intersperseElements(
                makeRateDetailSubtitleComponents({
                  ratingMethod,
                  metadata,
                  type: RateDetailSubtitleType.MILEAGE,
                }),
                'custom'
              )}
            </Text>
            {getMoreInfo({
              metadata,
              ratingMethod,
            })}
          </div>
        </div>
      </InteractableComponent>
    </div>
  );
};
