import { z } from 'zod';

export enum PricingStrategyType {
  SIMPLE_MARGIN = 'SIMPLE_MARGIN',
  MILEAGE_BASED = 'MILEAGE_BASED',
  MILEAGE_RATE = 'MILEAGE_RATE',
}

export enum RateComponentType {
  LINE_HAUL = 'LINE_HAUL',
  FUEL_SURCHARGE = 'FUEL_SURCHARGE',
  MANUAL_ADJUSTMENT = 'MANUAL_ADJUSTMENT',
  MARGIN = 'MARGIN',
  TMS_ADJUSTMENT = 'TMS_ADJUSTMENT',
  MESSAGE_ADJUSTMENT = 'MESSAGE_ADJUSTMENT',
  TOTAL = 'TOTAL',
  OTHER = 'OTHER',
}

export enum RatingMethod {
  ARCBEST_TL_QUOTING = 'ARCBEST_TL_QUOTING',
  DAT = 'DAT',
  ECHO = 'ECHO',
  GREENSCREENS = 'GREENSCREENS',
  HUMAN_INPUT = 'HUMAN_INPUT',
  CUSTOM = 'CUSTOM',
  SUNSET = 'SUNSET',
  NFI = 'NFI',
  TABI = 'TABI',
  WERNER = 'WERNER',
  TRANSFIX = 'TRANSFIX',
  BITFREIGHTER = 'BITFREIGHTER',
  WWEX = 'WWEX',
  DIRECT_OFFER = 'DIRECT_OFFER',
}

export const PricingStrategyConfigurationBaseSchema = z.object({});
export type PricingStrategyConfigurationBase = z.infer<
  typeof PricingStrategyConfigurationBaseSchema
>;

export const SimpleLLMPricingCalculatorConfigSchema =
  PricingStrategyConfigurationBaseSchema.extend({
    llmLineItemPrompt: z.string().optional(),
  });
export type SimpleLLMPricingCalculatorConfig = z.infer<
  typeof SimpleLLMPricingCalculatorConfigSchema
>;

// CarrierRateBasedPricingCalculatorConfig Schema
export const CarrierRateBasedPricingCalculatorConfigSchema =
  SimpleLLMPricingCalculatorConfigSchema.extend({
    minimumMargin: z.number().optional(),
    ratingMethod: z.nativeEnum(RatingMethod),
  });
export type CarrierRateBasedPricingCalculatorConfig = z.infer<
  typeof CarrierRateBasedPricingCalculatorConfigSchema
>;

const ensureZodMarginOrMarkup = (data: {
  marginPercentage?: number;
  markupDollars?: number;
}) =>
  (data.marginPercentage === undefined) !== (data.markupDollars === undefined);

const ensureZodMarginOrMarkupMessage = {
  message:
    "Exactly one of 'marginPercentage' or 'markupDollars' must be provided",
  path: ['marginPercentage', 'markupDollars'],
};

// MarginMarkupConfig Schema
export const MarginMarkupConfigSchema = z.object({
  marginPercentage: z.number().optional(),
  markupDollars: z.number().optional(),
});
export type MarginMarkupConfig = z.infer<typeof MarginMarkupConfigSchema>;

// SimpleMarginConfig Schema
export const SimpleMarginConfigSchema =
  CarrierRateBasedPricingCalculatorConfigSchema.merge(MarginMarkupConfigSchema)
    .strict()
    .refine(ensureZodMarginOrMarkup, ensureZodMarginOrMarkupMessage);
export type SimpleMarginConfig = z.infer<typeof SimpleMarginConfigSchema>;

// MileageMargin Schema
export const MileageMarginSchema = MarginMarkupConfigSchema.extend({
  lteMiles: z.number().optional(),
  gtMiles: z.number(),
})
  .strict()
  .refine(ensureZodMarginOrMarkup, ensureZodMarginOrMarkupMessage);
export type MileageMargin = z.infer<typeof MileageMarginSchema>;

export const MileageRateSchema = z
  .object({
    lteMiles: z.number().optional(),
    gtMiles: z.number(),
    perMileRateCents: z.number(),
  })
  .strict();
export type MileageRate = z.infer<typeof MileageRateSchema>;

// MileageBasedConfig Schema
export const MileageBasedConfigSchema =
  CarrierRateBasedPricingCalculatorConfigSchema.extend({
    mileageMargins: z.array(MileageMarginSchema),
  }).strict();
export type MileageBasedConfig = z.infer<typeof MileageBasedConfigSchema>;

// MileageRateConfig Schema
export const MileageRateConfigSchema =
  SimpleLLMPricingCalculatorConfigSchema.extend({
    mileageRates: z.array(MileageRateSchema),
  }).strict();
export type MileageRateConfig = z.infer<typeof MileageRateConfigSchema>;

// PricingStrategyConfiguration Schema
export const PricingStrategyConfigurationSchema = z.union([
  SimpleMarginConfigSchema,
  MileageBasedConfigSchema,
  MileageRateConfigSchema,
]);
export type PricingStrategyConfiguration = z.infer<
  typeof PricingStrategyConfigurationSchema
>;
