import { Option, Select } from '@shared/components';
import {
  AvailabilityCheckFields,
  OpendockDock,
  OpendockLoadType,
  OpendockWarehouse,
} from '@shared/generated/graphql';
import {
  OpendockAvailabilityInputFields,
  OpendockScheduleAppointmentInputFields,
} from 'clerk_common/types';
import { useEffect } from 'react';

type Props = {
  fields: AvailabilityCheckFields;
  handleFieldsChange: (i: OpendockAvailabilityInputFields) => Promise<void>;
  setInputFields: (i: OpendockAvailabilityInputFields) => void;
};

export function OpendockFields({
  fields,
  handleFieldsChange,
  setInputFields,
}: Props) {
  async function handleWarehouseChange(value: OpendockWarehouse) {
    const clickButNoChange = value.id == fields.warehouse.id;
    if (clickButNoChange) return;

    const inputFields: OpendockScheduleAppointmentInputFields = {
      warehouseId: value.id,
      loadTypeId: fields.loadType.id,
      dockId: fields.dock.id,
    };
    await handleFieldsChange(inputFields);
    setInputFields(inputFields);
  }

  async function handleLoadTypeChange(value: OpendockLoadType) {
    const clickButNoChange = value.id == fields.loadType.id;
    if (clickButNoChange) return;

    const inputFields: OpendockScheduleAppointmentInputFields = {
      warehouseId: fields.warehouse.id,
      loadTypeId: value.id,
      dockId: fields.dock.id,
    };
    await handleFieldsChange(inputFields);
    setInputFields(inputFields);
  }

  async function handleDockChange(value: OpendockDock) {
    const clickButNoChange = value.id == fields.dock.id;
    if (clickButNoChange) return;

    const inputFields: OpendockScheduleAppointmentInputFields = {
      warehouseId: fields.warehouse.id,
      loadTypeId: fields.loadType.id,
      dockId: value.id,
    };
    await handleFieldsChange(inputFields);
    setInputFields(inputFields);
  }

  // NOTE(jonathan): useEffect here is an unusual pattern of a child component
  // setting default (initial) state owned by a parent. There is probably a better/
  // cleaner way.
  useEffect(() => {
    const inputFields: OpendockScheduleAppointmentInputFields = {
      warehouseId: fields.warehouse.id,
      loadTypeId: fields.loadType.id,
      dockId: fields.dock.id,
    };
    setInputFields(inputFields);
    console.log('call setInputFields from hook');
  }, []);

  // NOTE(jonathan): We use find() because <Select> needs reference equality.
  const warehouse = find(fields.warehouses, fields.warehouse);
  const loadType = find(fields.loadTypes, fields.loadType);
  const dock = find(fields.docks, fields.dock);

  if (fields.warehouses.length == 0) return null;
  if (fields.loadTypes.length == 0) return null;
  if (fields.docks.length == 0) return null;

  return (
    <div>
      <Select
        value={warehouse}
        renderValue={renderWarehouse}
        className="mb-3"
        onChange={handleWarehouseChange}
      >
        {fields.warehouses.map((w) => (
          <Option value={w} key={w.id}>
            {w.name}
          </Option>
        ))}
      </Select>
      <Select
        value={loadType}
        renderValue={renderLoadType}
        className="mb-3"
        onChange={handleLoadTypeChange}
      >
        {fields.loadTypes.map((l) => (
          <Option value={l} key={l.id}>
            {l.name}
          </Option>
        ))}
      </Select>
      <Select
        value={dock}
        renderValue={renderDock}
        className="mb-3"
        onChange={handleDockChange}
      >
        {fields.docks.map((d) => (
          <Option value={d} key={d.id}>
            {d.name}
          </Option>
        ))}
      </Select>
    </div>
  );
}

function renderWarehouse(w: OpendockWarehouse) {
  return w.name;
}

function renderLoadType(l: OpendockLoadType) {
  return l.name;
}

function renderDock(d: OpendockDock) {
  return d.name;
}

function find<T extends { id: string }>(arr: T[], elem: T): T {
  const result = arr.find((i) => i.id == elem.id);
  // NOTE(jonathan): Fail silently.
  if (!result) return arr[0];
  return result;
}
