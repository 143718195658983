import {
  IntegrationType,
  OrderEventType,
  OrderFragment,
  OrderPreviewFragment,
  OriginatorStatus,
  ProcessingStatus,
} from '@shared/generated/graphql';
import {
  processingStatusFromEvents,
  StatusString,
} from '@shared/graphql/processingStatus';
import {
  ConfigOverrides,
  IntegrationConfigs,
  Order,
  OrderPreview,
} from '@shared/types/order';
import { isNil } from 'lodash';
import { jobEventsToStatusAdaptor } from './job';

export const orderEventAdaptor = {
  isProcessingEvent: (type?: OrderEventType | null) => {
    if (!type) return false;
    const kProcessingEvents = [
      OrderEventType.AI_PROCESSING_TRIGGERED,
      OrderEventType.AI_PROCESSING_COMPLETED,
      OrderEventType.AI_PROCESSING_FAILED,
    ];
    return kProcessingEvents.includes(type);
  },
  isTriggeredEvent: (type?: OrderEventType | null) => {
    return type === OrderEventType.AI_PROCESSING_TRIGGERED;
  },
  isCompletedEvent: (type?: OrderEventType | null) => {
    return type === OrderEventType.AI_PROCESSING_COMPLETED;
  },
  isFailedEvent: (type?: OrderEventType | null) => {
    return type === OrderEventType.AI_PROCESSING_FAILED;
  },
  toStatus: (str: StatusString) => {
    return str as ProcessingStatus;
  },
};

export function orderFromFragment(fragment: OrderFragment): Order {
  const isOnlyOrderInJob = fragment.job.orders.length === 1;
  return {
    id: fragment.id,
    status: processingStatusFromEvents(fragment.orderEvents, orderEventAdaptor),
    createdAt: new Date(fragment.createdAt),
    updatedAt: new Date(fragment.updatedAt ?? fragment.createdAt),
    organization: {
      id: fragment.organization.id,
      integrationConfigs: (fragment.organization
        .integrationConfigs as IntegrationConfigs) ?? {
        integrations: [],
      },
    },
    job: {
      id: fragment.job.id,
      emailMetadata: fragment.job.emailMetadata ?? null,
    },
    orderEvents: fragment.orderEvents ?? [],
    documents: fragment.documents.map((doc) => ({
      id: doc.id,
      url: doc.upload?.url ?? '',
    })),
    originator: {
      name: fragment.originator?.name ?? undefined,
      id: fragment.originator?.id ?? undefined,
      transmitIds: fragment.originator?.transmitIds ?? [],
      status: fragment.originator?.status ?? OriginatorStatus.TEST,
      externalIdMappings: (fragment.originator?.externalIdMappings ?? []).map(
        (eim) => ({
          id: eim.id,
          integrationType: eim.integrationType as IntegrationType,
          externalId: eim.externalId,
        })
      ),
      configOverrides:
        (fragment.originator?.configOverrides as ConfigOverrides) ?? undefined,
    },
    transmitId: fragment.transmitId ?? undefined,
    user: fragment.user
      ? {
          id: fragment.user.id,
          email: fragment.user.email ?? undefined,
        }
      : undefined,
    primaryReference: fragment.primaryReference ?? undefined,
    extractedData: JSON.parse(fragment.extractedData ?? '{}'),
    isOnlyOrderInJob,
    parentJobStatus: processingStatusFromEvents(
      fragment.job.jobEvents,
      jobEventsToStatusAdaptor
    ),
    isRedacted: !isNil(fragment.job.redactedAt),
    hasBeenSubmitted: fragment.hasSubmittedEvent,
    externalDeepLink: fragment.externalDeepLink,
    externalId: fragment.externalId,
  };
}

export function orderFromPreviewFragment(
  fragment: OrderPreviewFragment
): OrderPreview {
  return {
    id: fragment.id,
    createdAt: new Date(fragment.createdAt),
    updatedAt: new Date(fragment.updatedAt ?? fragment.createdAt),
    createdAtDisplay: new Date(fragment.createdAtDisplay),
    originator: {
      name: fragment.originator?.name ?? undefined,
      id: fragment.originator?.id ?? undefined,
    },
    user: fragment.user
      ? {
          id: fragment.user.id,
          email: fragment.user.email ?? undefined,
        }
      : undefined,
    primaryReference: fragment.primaryReference ?? undefined,
    status: fragment.processingStatus,
    hasBeenSubmitted: fragment.hasSubmittedEvent,
    isRedacted: !isNil(fragment.job.redactedAt),
  };
}
