import {
  createCircleChecked,
  createCircleInfo,
  createRingWarning,
} from '@shared/assets/icons';
import { Button, ButtonProps, Icon } from '@shared/components';
import { useOptionalRef } from '@shared/hooks';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import type { ForwardedRef, ReactElement, ReactNode } from 'react';
import { forwardRef } from 'react';
import { DismissButton } from '../dismiss-button';
import type { LinkProps } from '../link';
import { Link } from '../link';

export type ToastActionProps = Omit<LinkProps, 'variant' | 'children'> & {
  /** The action's label */
  label: string;

  /**
   * Whether the action should dismiss the toast
   */
  isDismissAction?: boolean;
};

export type ToastButtonActionProps = Omit<ButtonProps, 'children'> & {
  /** The action's label */
  label: string;

  /**
   * Whether the action should dismiss the toast
   */
  isDismissAction?: boolean;
};

export type ToastProps = {
  /** The title of the toast. */
  title: string;

  /**
   * Whether the toast should show a success or error icon.
   * @default "default"
   */
  variant?: 'default' | 'success' | 'error' | 'info';

  /**
   * Whether to show a dismiss button for the toast.
   * @default true
   */
  isDismissible?: boolean;

  /**
   * A description for the toast body.
   */
  description?: string | ReactElement;

  /**
   * Optional actions to display on the toast.
   */
  actions?: ToastActionProps[];

  /**
   * Optional button actions to display on the toast.
   */
  buttonActions?: ToastButtonActionProps[];

  /**
   * Handler called when dismiss button is pressed.
   */
  onDismiss?: () => void;

  /**
   * Used in compact contexts like Outlook add-in
   */
  compact?: boolean;

  children?: ReactNode;
};

// config

const ROOT = makeRootClassName('Toast');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  variant: 'default',
  isDismissible: true,
} as const;

const SUCCESS_ICON = createCircleChecked;
const ERROR_ICON = createRingWarning;
const INFO_ICON = createCircleInfo;

// utils

const getIcon = (variant: ToastProps['variant']) => {
  switch (variant) {
    case 'success':
      return SUCCESS_ICON;
    case 'error':
      return ERROR_ICON;
    case 'info':
      return INFO_ICON;
    default:
      throw new Error('Unknown variant');
  }
};

// main

const splitDescription = (description: string) => {
  return description.split('\n').map((line, idx, array) => (
    <>
      {line}
      {idx === array.length - 1 ? null : <br />}
    </>
  ));
};

function ToastComponent(
  props: ToastProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const domRef = useOptionalRef(ref);

  const hasIcon =
    p.variant === 'success' || p.variant === 'error' || p.variant === 'info';
  const isInline = p.actions?.length === 1;

  return (
    <div
      ref={domRef}
      className={clsx(
        `${ROOT} variant-${p.variant}`,
        p.compact && el`compact`,
        {
          'has-description': p.description,
          'has-icon': hasIcon,
          'has-actions': p.actions?.length,
          'has-single-action': p.actions?.length === 1,
          'is-inline': isInline,
          'is-dismissible': p.isDismissible,
        }
      )}
    >
      {hasIcon && <Icon content={getIcon(p.variant)} className={el`icon`} />}
      <div className={el`content`}>
        <p className={el`title`}>{p.title}</p>
        {p.description && (
          <p className={el`description`}>
            {typeof p.description === 'string'
              ? splitDescription(p.description)
              : p.description}
          </p>
        )}
        {props.children}
        <div className={el`actions-container`}>
          {p.actions && (
            <div className={el`actions`}>
              {p.actions.map(({ label, ...linkProps }) => (
                <Link key={label} {...linkProps}>
                  {label}
                </Link>
              ))}
            </div>
          )}
          {p.buttonActions && (
            <div className={clsx(el`actions`, el`button-actions`)}>
              {p.buttonActions.map(({ label, ...buttonProps }) => (
                <Button key={label} {...buttonProps}>
                  {label}
                </Button>
              ))}
            </div>
          )}
        </div>
      </div>
      {p.isDismissible && (
        <DismissButton
          onPress={p.onDismiss}
          className={el`dismiss-button`}
          size="xs"
        />
      )}
    </div>
  );
}

/**
 * A notification that displays relevant information. It can contain up to two actions and
 * can be dismissed.
 */
const Toast = forwardRef<HTMLDivElement, ToastProps>(ToastComponent);

export default Toast;
