import { Button, Text, Toggle, Tooltip } from '@shared/components';
import { CircularProgress } from '@shared/components/plugin-components';
import { useToast } from '@shared/components/toast';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { useLogosContext } from '@shared/contexts/pluginContexts/LogosURLsContext';
import {
  GetLoadBoardPostingsDocument,
  IntegrationType,
  LoadBoardPosting,
  usePostToLoadBoardMutation,
  useUnpostFromLoadBoardMutation,
} from '@shared/generated/graphql';
import { useGetLoadBoardPostings } from '@shared/hooks/load-boards/useGetLoadBoardPostings';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { isNil } from 'lodash';
import { useState } from 'react';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { InformationNeeded } from './InformationNeeded';

const ROOT = makeRootClassName('LoadboardDetails');
export const el = makeElementClassNameFactory(ROOT);

type LoadboardDetailsProps = {
  shipmentId: string;
};
export const LoadboardDetails = ({ shipmentId }: LoadboardDetailsProps) => {
  const { me } = useMeContext();
  const { postings, loading } = useGetLoadBoardPostings({ shipmentId });
  const hasPhoneNumber = !isNil(me?.profile?.phoneNumber);

  if (!hasPhoneNumber)
    return (
      <div className="flex flex-col gap-2">
        <Text type="body-sm" className="text-gray-500">
          Loadboards
        </Text>
        <InformationNeeded />
      </div>
    );

  return (
    <div className="flex flex-col gap-3">
      <Text type="body-sm" className="text-gray-500">
        Loadboards
      </Text>
      {loading ? (
        <div className="flex items-center justify-center">
          <CircularProgress size="xs" />
        </div>
      ) : (
        <LoadBoardPostingLine
          integrationType={IntegrationType.DAT}
          posting={postings[IntegrationType.DAT]}
          shipmentId={shipmentId}
        />
      )}
    </div>
  );
};

function stringifyIntegrationType(integrationType: IntegrationType) {
  switch (integrationType) {
    case IntegrationType.DAT:
      return 'DAT';
    default:
      return integrationType;
  }
}

type LoadBoardPostingLineProps = {
  shipmentId: string;
  posting: LoadBoardPosting;
  integrationType: IntegrationType;
};

function LoadBoardPostingLine({
  shipmentId,
  posting,
  integrationType,
}: LoadBoardPostingLineProps) {
  const { sendToast } = useToast();
  const [hasBeenUnposted, setHasBeenUnposted] = useState(false);
  const [postToLB, { loading: postLoading }] = usePostToLoadBoardMutation();
  const [unpostFromLB, { loading: unpostLoading }] =
    useUnpostFromLoadBoardMutation();
  const lbString = stringifyIntegrationType(integrationType);
  const logos = useLogosContext();

  async function postToLoadBoard() {
    await postToLB({
      variables: { input: { shipmentId, integrationType } },
      refetchQueries: [GetLoadBoardPostingsDocument],
    })
      .then((res) => {
        const externalIdMapping = res.data?.postToLoadBoard?.externalIdMapping;
        sendToast(`Posted to ${lbString}`, {
          description: `Your shipment has been posted to ${lbString}. ${externalIdMapping?.externalId}`,
          variant: 'success',
        });
      })
      .catch((e) => {
        sendToast(`Failed to post to ${lbString}`, {
          description: e.message,
          variant: 'error',
        });
      });
  }

  async function unpostFromLoadBoard() {
    await unpostFromLB({
      variables: { input: { shipmentId, integrationType } },
      refetchQueries: [GetLoadBoardPostingsDocument],
    })
      .then(() => {
        sendToast(`Unposted from ${lbString}`, {
          variant: 'success',
        });
      })
      .catch((e) => {
        sendToast(`Failed to unpost from ${lbString}`, {
          description: e.message,
          variant: 'error',
        });
      });
  }

  const handleToggle = (value: string) => {
    if (value === 'posted') {
      postToLoadBoard();
    } else {
      unpostFromLoadBoard();
      // TODO(mike): This shouldn't be necessary but refetch is not working
      // as expected. I need to figure out why.
      setHasBeenUnposted(true);
    }
  };

  if (hasBeenUnposted) {
    return (
      <div className="flex flex-row items-center gap-4">
        <Logo src={logos.DAT} tooltip={`DAT loadboard`} width={48} />
        <Text className="text-gray-500" type="body-sm">
          Posting deleted
        </Text>
      </div>
    );
  }

  const isPosted = posting && posting.externalId;
  const postingUrl = posting?.postingUrl;
  const loading = postLoading || unpostLoading;
  return (
    <div className="flex flex-row items-center gap-4">
      <Logo src={logos.DAT} tooltip={`DAT loadboard`} width={48} />
      <Toggle
        value={isPosted ? 'posted' : 'unposted'}
        onValueChange={handleToggle}
        isDisabled={hasBeenUnposted}
      >
        {[
          { label: 'Unposted', value: 'unposted' },
          { label: 'Posted', value: 'posted' },
        ]}
      </Toggle>
      {loading && <CircularProgress size="xs" />}
      {!isNil(postingUrl) && (
        <Button
          variant="secondary"
          size="xs"
          icon={<MdOutlineOpenInNew size={16} className="text-brand-500" />}
          tooltip={`View on DAT`}
          onPress={() => window.open(postingUrl, '_blank')}
        />
      )}
    </div>
  );
}

type LogoProps = {
  src: string;
  tooltip: string;
  width?: number;
  imgClassName?: string;
};
function Logo({ src, tooltip, imgClassName, width = 32 }: LogoProps) {
  return (
    <Tooltip content={tooltip} isInstant>
      <div className="rounded-md border p-[2px]">
        <img className={imgClassName} width={width} src={src} alt={tooltip} />
      </div>
    </Tooltip>
  );
}
