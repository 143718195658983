import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import { Button } from '@shared/components/button';
import { InteractableComponent } from '@shared/components/interactable-component';
import { Link } from '@shared/components/link';
import { Popover } from '@shared/components/popover';
import { Text } from '@shared/components/text';
import { Tooltip } from '@shared/components/tooltip';
import { Quote } from '@shared/types/quote';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { useMemo, useState } from 'react';
import { GoChevronDown, GoChevronUp } from 'react-icons/go';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { PiInfo } from 'react-icons/pi';
import { useLocalStorage } from 'usehooks-ts';
import {
  getDATLoadSearchUrl,
  getDATRateViewUrl,
} from '../../utils/getDATRateUrl';

// this thing
const VOOMA_CHROME_EXTENSION_URL =
  'https://chromewebstore.google.com/detail/vooma/oplfiodinlnnpdkpkcadigjnnfhdmncj';

const ROOT = makeRootClassName('QuoteExternalLinks');
const el = makeElementClassNameFactory(ROOT);

type LinkOption = {
  url: string;
  label: string;
  tooltip: string;
  value: LinkType;
};

enum LinkType {
  EXTERNAL_DEEP_LINK = 'EXTERNAL_DEEP_LINK',
  DAT_RATE_VIEW_AUTOMATION = 'DAT_RATE_VIEW_AUTOMATION',
  DAT_LOAD_SEARCH_AUTOMATION = 'DAT_LOAD_SEARCH_AUTOMATION',
}

type QuoteExternalLinksProps = {
  quote: Quote;
};

const maybeArrayWithObj = (obj: any, key?: string) => (key ? [obj] : []);

export function QuoteExternalLinks(p: QuoteExternalLinksProps) {
  const [defaultQuoteLinkId, setDefaultQuoteLinkId] = useLocalStorage<
    string | undefined
  >('default-quote-link-id', undefined);
  const [selectedLinkId, setSelectedLinkId] = useState<string | undefined>();

  const [open, setOpen] = useState(false);

  const getLinks = (): LinkOption[] => {
    const externalDeepUrl = p.quote.externalDeepLink;
    const datRateViewAutomationUrl = getDATRateViewUrl(p.quote);
    const datLoadSearchAutomationUrl = getDATLoadSearchUrl(p.quote);

    return [
      ...maybeArrayWithObj(
        {
          url: externalDeepUrl,
          label: 'External deep link',
          tooltip: 'Open in external system',
          value: LinkType.EXTERNAL_DEEP_LINK,
        },
        externalDeepUrl
      ),
      ...maybeArrayWithObj(
        {
          url: datRateViewAutomationUrl,
          label: 'DAT RateView automation',
          tooltip: 'Open in RateView and run automation',
          value: LinkType.DAT_RATE_VIEW_AUTOMATION,
        },
        datRateViewAutomationUrl
      ),
      ...maybeArrayWithObj(
        {
          url: datLoadSearchAutomationUrl,
          label: 'DAT LoadSearch automation',
          tooltip: 'Open in LoadSearch and run automation',
          value: LinkType.DAT_LOAD_SEARCH_AUTOMATION,
        },
        datLoadSearchAutomationUrl
      ),
    ];
  };

  const handleOpenLink = (option: LinkOption) => {
    setDefaultQuoteLinkId(option.value);
    window.open(option.url, '_blank');
  };

  const displayLink = useMemo(() => {
    const displayLinkId = selectedLinkId || defaultQuoteLinkId;
    const links = getLinks();

    const link = links.find((link) => link.value === displayLinkId);

    return link ?? links[0];
  }, [selectedLinkId, defaultQuoteLinkId]);

  return (
    <div className={ROOT}>
      <Popover
        content={
          <div className={el`popover-content`}>
            <Text type="body-sm" isHeavy>
              External resources
            </Text>
            <Text type="body-sm">
              External quote resources include the Vooma DAT automations powered
              by the Vooma Chrome Extension. Additionally, links to external
              systems of record are included if applicable.
            </Text>
            <Link
              size="small"
              href={VOOMA_CHROME_EXTENSION_URL}
              target="_blank"
            >
              Download the Vooma Chrome Extension
            </Link>
          </div>
        }
        side="top"
      >
        <Button
          className={el`more-info`}
          size="xs"
          variant="secondary"
          icon={<PiInfo size={16} />}
        />
      </Popover>
      <RadixDropdown.Root open={open} onOpenChange={(open) => setOpen(open)}>
        <RadixDropdown.Trigger className={el`radix-trigger`}>
          <InteractableComponent className="w-full">
            <div className={el`trigger`}>
              <Button
                size="xs"
                icon={
                  <MdOutlineOpenInNew size={16} className="text-brand-500" />
                }
                className={el`button-link`}
                tooltip={displayLink.tooltip}
                variant="secondary"
                iconPosition="right"
                onPress={() => handleOpenLink(displayLink)}
              >
                {displayLink?.label ?? 'Select link...'}
              </Button>
              <Button
                size="xs"
                variant="secondary"
                icon={
                  open ? <GoChevronUp size={16} /> : <GoChevronDown size={16} />
                }
                className={el`button-dropdown`}
                tooltip={'Additional resources'}
                onPress={() => setOpen(!open)}
              />
            </div>
          </InteractableComponent>
        </RadixDropdown.Trigger>
        <RadixDropdown.Content align="end" className={el`menu`}>
          {getLinks().map((link) => (
            <RadixDropdown.Item
              key={link.value}
              className={el`menu-item`}
              onClick={() => setSelectedLinkId(link.value)}
            >
              <Tooltip content={link.tooltip} side="left">
                <div>
                  <Text type="body-xs">{link.label}</Text>
                </div>
              </Tooltip>
            </RadixDropdown.Item>
          ))}
        </RadixDropdown.Content>
      </RadixDropdown.Root>
    </div>
  );
}
